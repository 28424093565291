// Responsiveness

$variable-columns: true !default;

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 64px !default;

// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px !default;

// 960px container + 4rem
$desktop: 960px + 2 * $gap !default;

// 1152px container + 4rem
$widescreen: 1152px + 2 * $gap !default;
$widescreen-enabled: true !default;

// 1344px container + 4rem
$fullhd: 1344px + 2 * $gap !default;
$fullhd-enabled: true !default;

// Responsiveness
@mixin from($device) {
	@media screen and (min-width:$device) {
		@content;
	}

}

@mixin until($device) {
	@media screen and (max-width:$device - 1px) {
		@content;
	}

}

@mixin mobile {
	@media screen and (max-width:$tablet - 1px) {
		@content;
	}

}

@mixin tablet {
	@media screen and (min-width:$tablet), print {
		@content;
	}

}

@mixin tablet-only {
	@media screen and (min-width:$tablet) and (max-width:$desktop - 1px) {
		@content;
	}

}

@mixin touch {
	@media screen and (max-width:$desktop - 1px) {
		@content;
	}

}

@mixin desktop {
	@media screen and (min-width:$desktop) {
		@content;
	}

}

@mixin desktop-only {
	@if $widescreen-enabled {
		@media screen and (min-width:$desktop) and (max-width:$widescreen - 1px) {
			@content;
		}

	}

}

@mixin until-widescreen {
	@if $widescreen-enabled {
		@media screen and (max-width:$widescreen - 1px) {
			@content;
		}

	}

}

@mixin widescreen {
	@if $widescreen-enabled {
		@media screen and (min-width:$widescreen) {
			@content;
		}

	}

}

@mixin widescreen-only {
	@if $widescreen-enabled and $fullhd-enabled {
		@media screen and (min-width:$widescreen) and (max-width:$fullhd - 1px) {
			@content;
		}

	}

}

@mixin until-fullhd {
	@if $fullhd-enabled {
		@media screen and (max-width:$fullhd - 1px) {
			@content;
		}

	}

}

@mixin fullhd {
	@if $fullhd-enabled {
		@media screen and (min-width:$fullhd) {
			@content;
		}

	}

}

$column-gap: 0.75rem !default;
// $grid-gutter-width = 1.5rem

.column {
	display: block;
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 1;
	padding: $column-gap;

	.columns.is-mobile > &.is-narrow {
		flex: none;
	}

	.columns.is-mobile > &.is-full {
		flex: none;
		width: 100%;
	}

	.columns.is-mobile > &.is-three-quarters {
		flex: none;
		width: 75%;
	}

	.columns.is-mobile > &.is-two-thirds {
		flex: none;
		width: 66.6666%;
	}

	.columns.is-mobile > &.is-half {
		flex: none;
		width: 50%;
	}

	.columns.is-mobile > &.is-one-third {
		flex: none;
		width: 33.3333%;
	}

	.columns.is-mobile > &.is-one-quarter {
		flex: none;
		width: 25%;
	}

	.columns.is-mobile > &.is-one-fifth {
		flex: none;
		width: 20%;
	}

	.columns.is-mobile > &.is-two-fifths {
		flex: none;
		width: 40%;
	}

	.columns.is-mobile > &.is-three-fifths {
		flex: none;
		width: 60%;
	}

	.columns.is-mobile > &.is-four-fifths {
		flex: none;
		width: 80%;
	}

	.columns.is-mobile > &.is-offset-three-quarters {
		margin-left: 75%;
	}

	.columns.is-mobile > &.is-offset-two-thirds {
		margin-left: 66.6666%;
	}

	.columns.is-mobile > &.is-offset-half {
		margin-left: 50%;
	}

	.columns.is-mobile > &.is-offset-one-third {
		margin-left: 33.3333%;
	}

	.columns.is-mobile > &.is-offset-one-quarter {
		margin-left: 25%;
	}

	.columns.is-mobile > &.is-offset-one-fifth {
		margin-left: 20%;
	}

	.columns.is-mobile > &.is-offset-two-fifths {
		margin-left: 40%;
	}

	.columns.is-mobile > &.is-offset-three-fifths {
		margin-left: 60%;
	}

	.columns.is-mobile > &.is-offset-four-fifths {
		margin-left: 80%;
	}

	@for $i from 1 through 16 {
		.columns.is-mobile > &.is-#{$i}
		{
			flex: none;
			width: percentage($i / 16);
		}

		.columns.is-mobile > &.is-offset-#{$i}
		{
			margin-left: percentage($i / 16);
		}
	}

	@include mobile {
		&.is-narrow-mobile {
			flex: none;
		}

		&.is-full-mobile {
			flex: none;
			width: 100%;
		}

		&.is-three-quarters-mobile {
			flex: none;
			width: 75%;
		}

		&.is-two-thirds-mobile {
			flex: none;
			width: 66.6666%;
		}

		&.is-half-mobile {
			flex: none;
			width: 50%;
		}

		&.is-one-third-mobile {
			flex: none;
			width: 33.3333%;
		}

		&.is-one-quarter-mobile {
			flex: none;
			width: 25%;
		}

		&.is-one-fifth-mobile {
			flex: none;
			width: 20%;
		}

		&.is-two-fifths-mobile {
			flex: none;
			width: 40%;
		}

		&.is-three-fifths-mobile {
			flex: none;
			width: 60%;
		}

		&.is-four-fifths-mobile {
			flex: none;
			width: 80%;
		}

		&.is-offset-three-quarters-mobile {
			margin-left: 75%;
		}

		&.is-offset-two-thirds-mobile {
			margin-left: 66.6666%;
		}

		&.is-offset-half-mobile {
			margin-left: 50%;
		}

		&.is-offset-one-third-mobile {
			margin-left: 33.3333%;
		}

		&.is-offset-one-quarter-mobile {
			margin-left: 25%;
		}

		&.is-offset-one-fifth-mobile {
			margin-left: 20%;
		}

		&.is-offset-two-fifths-mobile {
			margin-left: 40%;
		}

		&.is-offset-three-fifths-mobile {
			margin-left: 60%;
		}

		&.is-offset-four-fifths-mobile {
			margin-left: 80%;
		}

		@for $i from 1 through 16 {
			&.is-#{$i}-mobile {
				flex: none;
				width: percentage($i / 16);
			}

			&.is-offset-#{$i}-mobile {
				margin-left: percentage($i / 16);
			}

		}

	}

	@include tablet {
		&.is-narrow, &.is-narrow-tablet {
			flex: none;
		}

		&.is-full, &.is-full-tablet {
			flex: none;
			width: 100%;
		}

		&.is-three-quarters, &.is-three-quarters-tablet {
			flex: none;
			width: 75%;
		}

		&.is-two-thirds, &.is-two-thirds-tablet {
			flex: none;
			width: 66.6666%;
		}

		&.is-half, &.is-half-tablet {
			flex: none;
			width: 50%;
		}

		&.is-one-third, &.is-one-third-tablet {
			flex: none;
			width: 33.3333%;
		}

		&.is-one-quarter, &.is-one-quarter-tablet {
			flex: none;
			width: 25%;
		}

		&.is-one-fifth, &.is-one-fifth-tablet {
			flex: none;
			width: 20%;
		}

		&.is-two-fifths, &.is-two-fifths-tablet {
			flex: none;
			width: 40%;
		}

		&.is-three-fifths, &.is-three-fifths-tablet {
			flex: none;
			width: 60%;
		}

		&.is-four-fifths, &.is-four-fifths-tablet {
			flex: none;
			width: 80%;
		}

		&.is-offset-three-quarters, &.is-offset-three-quarters-tablet {
			margin-left: 75%;
		}

		&.is-offset-two-thirds, &.is-offset-two-thirds-tablet {
			margin-left: 66.6666%;
		}

		&.is-offset-half, &.is-offset-half-tablet {
			margin-left: 50%;
		}

		&.is-offset-one-third, &.is-offset-one-third-tablet {
			margin-left: 33.3333%;
		}

		&.is-offset-one-quarter, &.is-offset-one-quarter-tablet {
			margin-left: 25%;
		}

		&.is-offset-one-fifth, &.is-offset-one-fifth-tablet {
			margin-left: 20%;
		}

		&.is-offset-two-fifths, &.is-offset-two-fifths-tablet {
			margin-left: 40%;
		}

		&.is-offset-three-fifths, &.is-offset-three-fifths-tablet {
			margin-left: 60%;
		}

		&.is-offset-four-fifths, &.is-offset-four-fifths-tablet {
			margin-left: 80%;
		}

		@for $i from 1 through 16 {
			&.is-#{$i}, &.is-#{$i}-tablet {
				flex: none;
				width: percentage($i / 16);
			}
			&.is-offset-#{$i}, &.is-offset-#{$i}-tablet {
				margin-left: percentage($i / 16);
			}

		}

	}

	@include touch {
		&.is-narrow-touch {
			flex: none;
		}

		&.is-full-touch {
			flex: none;
			width: 100%;
		}

		&.is-three-quarters-touch {
			flex: none;
			width: 75%;
		}

		&.is-two-thirds-touch {
			flex: none;
			width: 66.6666%;
		}

		&.is-half-touch {
			flex: none;
			width: 50%;
		}

		&.is-one-third-touch {
			flex: none;
			width: 33.3333%;
		}

		&.is-one-quarter-touch {
			flex: none;
			width: 25%;
		}

		&.is-one-fifth-touch {
			flex: none;
			width: 20%;
		}

		&.is-two-fifths-touch {
			flex: none;
			width: 40%;
		}

		&.is-three-fifths-touch {
			flex: none;
			width: 60%;
		}

		&.is-four-fifths-touch {
			flex: none;
			width: 80%;
		}

		&.is-offset-three-quarters-touch {
			margin-left: 75%;
		}

		&.is-offset-two-thirds-touch {
			margin-left: 66.6666%;
		}

		&.is-offset-half-touch {
			margin-left: 50%;
		}

		&.is-offset-one-third-touch {
			margin-left: 33.3333%;
		}

		&.is-offset-one-quarter-touch {
			margin-left: 25%;
		}

		&.is-offset-one-fifth-touch {
			margin-left: 20%;
		}

		&.is-offset-two-fifths-touch {
			margin-left: 40%;
		}

		&.is-offset-three-fifths-touch {
			margin-left: 60%;
		}

		&.is-offset-four-fifths-touch {
			margin-left: 80%;
		}

		@for $i from 1 through 16 {
			&.is-#{$i}-touch {
				flex: none;
				width: percentage($i / 16);
			}

			&.is-offset-#{$i}-touch {
				margin-left: percentage($i / 16);
			}

		}

	}

	@include desktop {
		&.is-narrow-desktop {
			flex: none;
		}

		&.is-full-desktop {
			flex: none;
			width: 100%;
		}

		&.is-three-quarters-desktop {
			flex: none;
			width: 75%;
		}

		&.is-two-thirds-desktop {
			flex: none;
			width: 66.6666%;
		}

		&.is-half-desktop {
			flex: none;
			width: 50%;
		}

		&.is-one-third-desktop {
			flex: none;
			width: 33.3333%;
		}

		&.is-one-quarter-desktop {
			flex: none;
			width: 25%;
		}

		&.is-one-fifth-desktop {
			flex: none;
			width: 20%;
		}

		&.is-two-fifths-desktop {
			flex: none;
			width: 40%;
		}

		&.is-three-fifths-desktop {
			flex: none;
			width: 60%;
		}

		&.is-four-fifths-desktop {
			flex: none;
			width: 80%;
		}

		&.is-offset-three-quarters-desktop {
			margin-left: 75%;
		}

		&.is-offset-two-thirds-desktop {
			margin-left: 66.6666%;
		}

		&.is-offset-half-desktop {
			margin-left: 50%;
		}

		&.is-offset-one-third-desktop {
			margin-left: 33.3333%;
		}

		&.is-offset-one-quarter-desktop {
			margin-left: 25%;
		}

		&.is-offset-one-fifth-desktop {
			margin-left: 20%;
		}

		&.is-offset-two-fifths-desktop {
			margin-left: 40%;
		}

		&.is-offset-three-fifths-desktop {
			margin-left: 60%;
		}

		&.is-offset-four-fifths-desktop {
			margin-left: 80%;
		}

		@for $i from 1 through 16 {
			&.is-#{$i}-desktop {
				flex: none;
				width: percentage($i / 16);
			}

			&.is-offset-#{$i}-desktop {
				margin-left: percentage($i / 16);
			}

		}

	}

	@include widescreen {
		&.is-narrow-widescreen {
			flex: none;
		}

		&.is-full-widescreen {
			flex: none;
			width: 100%;
		}

		&.is-three-quarters-widescreen {
			flex: none;
			width: 75%;
		}

		&.is-two-thirds-widescreen {
			flex: none;
			width: 66.6666%;
		}

		&.is-half-widescreen {
			flex: none;
			width: 50%;
		}

		&.is-one-third-widescreen {
			flex: none;
			width: 33.3333%;
		}

		&.is-one-quarter-widescreen {
			flex: none;
			width: 25%;
		}

		&.is-one-fifth-widescreen {
			flex: none;
			width: 20%;
		}

		&.is-two-fifths-widescreen {
			flex: none;
			width: 40%;
		}

		&.is-three-fifths-widescreen {
			flex: none;
			width: 60%;
		}

		&.is-four-fifths-widescreen {
			flex: none;
			width: 80%;
		}

		&.is-offset-three-quarters-widescreen {
			margin-left: 75%;
		}

		&.is-offset-two-thirds-widescreen {
			margin-left: 66.6666%;
		}

		&.is-offset-half-widescreen {
			margin-left: 50%;
		}

		&.is-offset-one-third-widescreen {
			margin-left: 33.3333%;
		}

		&.is-offset-one-quarter-widescreen {
			margin-left: 25%;
		}

		&.is-offset-one-fifth-widescreen {
			margin-left: 20%;
		}

		&.is-offset-two-fifths-widescreen {
			margin-left: 40%;
		}

		&.is-offset-three-fifths-widescreen {
			margin-left: 60%;
		}

		&.is-offset-four-fifths-widescreen {
			margin-left: 80%;
		}

		@for $i from 1 through 16 {
			&.is-#{$i}-widescreen {
				flex: none;
				width: percentage($i / 16);
			}

			&.is-offset-#{$i}-widescreen {
				margin-left: percentage($i / 16);
			}

		}

	}

	@include fullhd {
		&.is-narrow-fullhd {
			flex: none;
		}

		&.is-full-fullhd {
			flex: none;
			width: 100%;
		}

		&.is-three-quarters-fullhd {
			flex: none;
			width: 75%;
		}

		&.is-two-thirds-fullhd {
			flex: none;
			width: 66.6666%;
		}

		&.is-half-fullhd {
			flex: none;
			width: 50%;
		}

		&.is-one-third-fullhd {
			flex: none;
			width: 33.3333%;
		}

		&.is-one-quarter-fullhd {
			flex: none;
			width: 25%;
		}

		&.is-one-fifth-fullhd {
			flex: none;
			width: 20%;
		}

		&.is-two-fifths-fullhd {
			flex: none;
			width: 40%;
		}

		&.is-three-fifths-fullhd {
			flex: none;
			width: 60%;
		}

		&.is-four-fifths-fullhd {
			flex: none;
			width: 80%;
		}

		&.is-offset-three-quarters-fullhd {
			margin-left: 75%;
		}

		&.is-offset-two-thirds-fullhd {
			margin-left: 66.6666%;
		}

		&.is-offset-half-fullhd {
			margin-left: 50%;
		}

		&.is-offset-one-third-fullhd {
			margin-left: 33.3333%;
		}

		&.is-offset-one-quarter-fullhd {
			margin-left: 25%;
		}

		&.is-offset-one-fifth-fullhd {
			margin-left: 20%;
		}

		&.is-offset-two-fifths-fullhd {
			margin-left: 40%;
		}

		&.is-offset-three-fifths-fullhd {
			margin-left: 60%;
		}

		&.is-offset-four-fifths-fullhd {
			margin-left: 80%;
		}

		@for $i from 1 through 16 {
			&.is-#{$i}-fullhd {
				flex: none;
				width: percentage($i / 16);
			}

			&.is-offset-#{$i}-fullhd {
				margin-left: percentage($i / 16);
			}

		}

	}

}

.columns {
	margin-left: -$column-gap;
	margin-right: -$column-gap;
	margin-top: -$column-gap;

	&:last-child {
		margin-bottom: -$column-gap;
	}

	&:not(:last-child) {
		margin-bottom:calc(1.5rem - #{$column-gap});
	}

	// Modifiers
	&.is-centered {
		justify-content: center;
	}

	&.is-gapless {
		margin-left: 0;
		margin-right: 0;
		margin-top: 0;

		& > .column {
			margin: 0;
			padding: 0 !important;
		}

		&:not(:last-child) {
			margin-bottom: 1.5rem;
		}

		&:last-child {
			margin-bottom: 0;
		}

	}

	&.is-mobile {
		display: flex;
	}

	&.is-multiline {
		flex-wrap: wrap;
	}

	&.is-vcentered {
		align-items: center;
	}

	// Responsiveness
	@include tablet {
		&:not(.is-desktop) {
			display: flex;
		}

	}

	@include desktop {
		// Modifiers
		&.is-desktop {
			display: flex;
		}

	}

}

@if $variable-columns {
	.columns.is-variable {
		--columnGap: 0.75rem;
		margin-left: calc(-1 * var(--columnGap));
		margin-right: calc(-1 * var(--columnGap));

		.column {
			padding-left: var(--columnGap);
			padding-right: var(--columnGap);
		}

		@for $i from 0 through 8 {
			&.is-#{$i}
			{
				--columnGap:#{0.25rem};
			}

			@include mobile {
				&.is-#{$i}-mobile {
					--columnGap:#{0.25rem};
				}
			}

			@include tablet {
				&.is-#{$i}-tablet {
					--columnGap:#{0.25rem};
				}
			}

			@include tablet-only {
				&.is-#{$i}-tablet-only {
					--columnGap:#{0.25rem};
				}
			}

			@include touch {
				&.is-#{$i}-touch {
					--columnGap:#{0.25rem};
				}
			}

			@include desktop {
				&.is-#{$i}-desktop {
					--columnGap:#{0.25rem};
				}
			}

			@include desktop-only {
				&.is-#{$i}-desktop-only {
					--columnGap:#{0.25rem};
				}
			}

			@include widescreen {
				&.is-#{$i}-widescreen {
					--columnGap:#{0.25rem};
				}
			}

			@include widescreen-only {
				&.is-#{$i}-widescreen-only {
					--columnGap:#{0.25rem};
				}
			}

			@include fullhd {
				&.is-#{$i}-fullhd {
					--columnGap:#{0.25rem};
				}
			}

		}

	}

}
;@import "sass-embedded-legacy-load-done:43047";