/* stylelint-disable */

//
// Variables + Mixin
//
@import "bootstrap5.1"; // Bootstrap v5.1 + Prestashop v8.0 + Parent Theme v0.1.5

//
// Themes
//
@import "partials/fonts";
@import "partials/root";
// @import "partials/helpers";

//
// Core + Parent Theme
// 	Optimize : we can replace with our file
//

// Layout
@import "lodesse/layout/init";
@import "lodesse/layout/header";
@import "lodesse/layout/footer";
@import "lodesse/layout/megamenu";
// @import "_reference/hummingbird/layout/content";

// Components
@import "components/bulma_columns"; // bulma grid system
@import "components/buttons";
@import "lodesse/components/searchbar";
@import "lodesse/components/reviews";
@import "lodesse/components/products";
@import "lodesse/components/alert";

// Modules
@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";


// Modules override
@import "lodesse/modules/ps_customtextmessage";
@import "lodesse/modules/pm_advancedtopmenu";
@import "lodesse/modules/divioseo_videosslider";
@import "lodesse/modules/divioseo_hpanimation";
@import "lodesse/modules/divioseo_philosophydetails";
@import "lodesse/modules/divioseo_imageslist";
@import "lodesse/modules/divioseo_reassurance";
@import "lodesse/modules/divioseo_video";
@import "lodesse/modules/divioseo_testimony";
@import "lodesse/modules/divioseo_globalreviews";
@import "lodesse/modules/divioseo_specialproducts";
@import "lodesse/modules/divioseo_products";
@import "lodesse/modules/divioseo_tutorials";

// Pages

@import "lodesse/pages/category";
@import "lodesse/pages/home";
@import "lodesse/pages/order";
@import "lodesse/pages/product";
@import "lodesse/pages/cart";
@import "lodesse/pages/checkout";
@import "lodesse/pages/account";
@import "lodesse/pages/default";





/* stylelint-enable */
