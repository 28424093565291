.page-category{
    .block-category{
        background: $white;
        width: 40%;
        margin: 20px auto 80px;
        padding: 50px;
        position: relative;
        &::before{
            content: " ";
            position: absolute;
            left: 25px;
            top: 25px;
            right: 25px;
            bottom: 25px;
            border: 2px dashed $lightapricot;
            z-index: 0;
        }
        .page-title-section{
            margin: 0 !important;
            position: relative;
            z-index: 1;
            color:  $blackcat;
            text-align: center;
            font-size: 40px;
        }
        #category-description{
            margin: 20px 0 0 !important;
            position: relative;
            z-index: 1;
            color:  $blackcat;
            font-size: $font-size-md;
        }
    }
    .total-products{
        text-align: right;
    }
}
.products{
    justify-content: space-between;
    gap: 80px 5%;
    .product-miniature{
        width: 30%;
    }
}
.category-depth-level-1{    
    .products{
        .product-miniature{
            &:nth-child(4),
            &:nth-child(5) {
                width: 45%;
            }
            &:nth-child(12) {
                width: 100%;
                .card{
                    max-width: 45%;
                    margin: 0 auto;
                }
            }
        }
    }
}

.pagination{
    margin: 50px 0;
    .page-item{
        &::before{
            display: none;
        }
        .page-link{
            display: flex;
        }
    }
}



// Mobile - Two row
@include media-breakpoint-down(md) {
    .page-category{
        margin: 20px auto 60px;
        .block-category{
            width: 80%;
            .page-title-section{
                font-size: 30px;
            }
            #category-description{
                font-size: $font-size-md;
            }
        }
    }
    .products{
        gap: 50px 5%;
        .product-miniature{
            width: 45%;
        }
    }
    .category-depth-level-1{    
        .products{
            .product-miniature{
                &:nth-child(4),
                &:nth-child(5) {
                    width: 45%;
                }
            }
        }
    }
}

// Mobile - One row
@include media-breakpoint-down(sm) {
    .page-category{
        margin: 20px auto;
        .block-category{
            width: 100%;
            .page-title-section{
                font-size: 25px;
            }
            #category-description{
                font-size: $font-size-sm;
            }
        }
    }
    .products{
        gap: 40px 0;
        .product-miniature{
            width: 100%;
        }
    }
    .category-depth-level-1{    
        .products{
            .product-miniature{
                &:nth-child(4),
                &:nth-child(5) {
                    width: 100%;
                }
            }
        }
    }
}
;@import "sass-embedded-legacy-load-done:43093";