//
// Variables Hummingbird - Parent Theme
//

$accordion-padding-y:                     0 !default;
$accordion-padding-x:                     0 !default;

$accordion-border-width:                  0 !default;

$accordion-body-padding-y:                0.5rem !default;

$accordion-button-padding-y:              0.5rem !default;
$accordion-button-active-bg:              $white !default;
$accordion-button-active-color:           $body-color !default;

$accordion-button-focus-border-color:     $white !default;
$accordion-button-focus-box-shadow:       none !default;

$accordion-button-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23363a41' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !default;
$accordion-button-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23363a41' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !default;

$accordion-icon-width: 1rem !default;
$badge-font-size: 0.875rem !default;
$badge-font-weight: 500 !default;
$badge-border-radius: 4px !default;
$badge-padding-y: 0.3125rem !default;
$badge-padding-x: 0.625rem !default;
$border-radius: 8px !default;
$breadcrumb-font-size:              0.75rem !default;
$breadcrumb-padding-y:              1.75rem !default;
$breadcrumb-padding-x:              0 !default;
$breadcrumb-divider-color:          $gray-800 !default;
$breadcrumb-active-color:           $gray-800 !default;
$breadcrumb-divider:                quote(">") !default;
$btn-border-radius: 4px !default;
$card-border-width: 0 !default;
$card-border-radius: 8px !default;
$carousel-control-color:             $gray-700 !default;
$carousel-control-width:             4rem !default;
$carousel-control-icon-width:        1.5rem !default;
$carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !default;
$carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>") !default;


$carousel-indicator-width:           0.5rem !default;
$carousel-indicator-height:          0.5rem !default;
$carousel-indicator-spacer:          0.25rem !default;
$carousel-indicator-active-bg:       $primary !default;

$carousel-caption-color:             $gray-800 !default;

$dropdown-divider-margin-y:         $spacer * 0.375 !default;
$dropdown-item-padding-y:           $spacer * 0.5 !default;
$dropdown-link-color:               $gray-800 !default;
$dropdown-link-hover-color:         tint-color($gray-800, 30%) !default;
$dropdown-link-hover-bg:            transparent !default;
$dropdown-link-active-color:        $dropdown-link-hover-color !default;
$dropdown-link-active-bg:           $dropdown-link-hover-bg !default;
$font-family-base: "Inter", sans-serif !default;
$font-size-base: 1rem !default;
$line-height-base: 1.25 !default;
$form-text-color:                       $gray-700 !default;
$form-text-font-size:                   0.75rem !default;

$form-label-margin-bottom:              0.5rem !default;
$form-label-font-size:                  1rem !default;
$form-label-font-weight:                700 !default;
$form-label-color:                      $gray-800 !default;
$enable-cssgrid: true !default;
$headings-font-weight: 600 !default;

$input-padding-y:                       0.5rem !default;
$input-padding-x:                       1rem !default;
$input-font-size:                       1rem !default;
$input-font-weight:                     400 !default;
$input-line-height:                     1.5 !default;

$input-color:                           $gray-800 !default;
$input-border-color:                    $gray-400 !default;

$input-border-radius:                   4px !default;
$input-border-radius-sm:                4px !default;
$input-border-radius-lg:                4px !default;

$input-placeholder-color:               $gray-300 !default;
$input-plaintext-color:                 $gray-800 !default;
$modal-footer-border-width: 0 !default;
$nav-link-font-size:                1rem !default;
$nav-link-font-weight:              600 !default;
$nav-link-color:                    var(--bs-gray-800) !default;
$nav-link-hover-color:              var(--bs-primary) !default;
$nav-tabs-link-active-color: var(--bs-primary) !default;
$nav-tabs-border-width: 2px !default;
$nav-tabs-border-color: transparent !default;
$nav-tabs-link-hover-border-color:  transparent !default;
$nav-tabs-link-active-border-color: transparent transparent var(--bs-primary) !default;

;@import "sass-embedded-legacy-load-done:42872";