
/* CART */
.cart-grid{
    margin-bottom: 80px;
        
    .cart-grid__body,
    .cart-grid__right{
        .h4{
            margin: 20px 0 0;
            padding: 0;
            line-height: 30px;
            text-transform: uppercase;
            display: inline-block;
            width: 100%;
        }
        hr {
            margin: 20px 0;
        }
    }
    .cart-grid__body{
        .cart-container{
            display: inline-block;
            width: 100%;
            .cart-overview{
                background: $white;
                padding: 40px;
                border: 1px solid #bfbfbf;
                margin: 20px 0 0;
            }
        }

    }
    .cart-grid__right{
        .cart-summary{
            background: $white;
            padding: 40px ;
            border: 1px solid #bfbfbf;
            margin: 20px 0 0;

            .cart-detailed__subtotals{
                .cart-summary__line{
                    display: flex;
                    justify-content: space-between;
                    margin: 0 0 20px;
                }
            }
            .cart-summary__totals{

                .cart-summary__line{
                    &.cart-total{
                        display: flex;
                        justify-content: space-between;
                        margin: 20px 0;
                        border-top: 1px solid;
                        padding-top: 20px;
                        font-weight: bold;
                    }
                }
            }
        }
        .card-footer {
            padding: 0;
            color: var(--bs-card-cap-color);
            background-color: transparent;
            border-top: 0;
            .btn{
                width: 100%;
                text-transform: uppercase;
            }
        }

    }

    .product-line__title{
        font-weight: bold;
    }

    .cart__items{
        padding: 0;
        margin: 0;
        .cart__item{
            padding: 10px 0;
            margin: 10px 0;
            &::before{
                display: none;
            }
        }
    }

    .product-line__informations{
        .row{
            justify-content: space-between;
            align-items: center;
        }
    }

    .quantity-button{
        .btn {
            padding: 0;
            margin: 0;
        }
        input{
            padding: 5px;
            margin: 0;
            width: 40px;
            text-align: center;
            justify-content: space-around;
        }
    }
    
    .remove-from-cart{

    }
}

.cart-voucher{
    .accordion-item{
        border: 0 !important;
    }
    .accordion-button {
        padding: 15px 0 !important;
        margin: 0 !important;
    }
    .accordion-collapse{
        padding: 0 !important;
    }
}



.modal-open {
    overflow: hidden
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000000000;
    display: none;
    overflow: hidden;
    outline: 0
}

.modal.fade .modal-dialog {

}

.modal.in .modal-dialog {
    
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px
}

.modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 4.8px;
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.in {
    opacity: .5
}

.modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5
}

.modal-header::after {
    content: "";
    display: table;
    clear: both
}

.modal-header .close {
    margin-top: -2px;
    border: 0;
    background: 0;
    right: 20px;
    position: absolute;
}

.modal-title {
    margin: 0;
    line-height: 1.5
}

.modal-body {
    position: relative;
    padding: 15px
}

.modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5
}

.modal-footer::after {
    content: "";
    display: table;
    clear: both
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width:600px;
        margin: 30px auto
    }

    .modal-sm {
        max-width: 300px
    }
}

@media (min-width: 992px) {
    .modal-lg {
        max-width:900px
    }
}





#product-modal .modal-content {
    padding: 0;
    background: transparent;
    border: none
}

#product-modal .modal-content .modal-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -30%
}

#product-modal .modal-content .modal-body .product-cover-modal {
    background: #fff
}

#product-modal .modal-content .modal-body .image-caption {
    width: 800px;
    padding: 10px 20px;
    background: #fff;
    border-top: #f6f6f6 1px solid
}

#product-modal .modal-content .modal-body .image-caption p,#product-modal .modal-content .modal-body .image-caption .page-content.page-cms ul,.page-content.page-cms #product-modal .modal-content .modal-body .image-caption ul {
    margin-bottom: 0
}

#product-modal .modal-content .modal-body .thumbnails {
    position: relative
}

#product-modal .modal-content .modal-body .mask {
    position: relative;
    z-index: 1;
    max-height: 790px;
    margin-top: 35px;
    overflow: hidden
}

#product-modal .modal-content .modal-body .mask.nomargin {
    margin-top: 0
}

#product-modal .modal-content .modal-body .product-images {
    margin-left: 40px
}

#product-modal .modal-content .modal-body .product-images img {
    width: 148px;
    cursor: pointer;
    background: #fff
}

#product-modal .modal-content .modal-body .product-images img:hover {
    border: #1B1D37 3px solid
}

#product-modal .modal-content .modal-body .arrows {
    position: absolute;
    top: 0;
    right: 30px;
    z-index: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    text-align: center;
    cursor: pointer
}

#product-modal .modal-content .modal-body .arrows .arrow-up {
    position: absolute;
    top: -32px;
    opacity: .2
}

#product-modal .modal-content .modal-body .arrows .arrow-down {
    position: absolute;
    bottom: -32px
}

#product-modal .modal-content .modal-body .arrows i {
    display: inline;
    font-size: 100px
}

#blockcart-modal {
    color: #232323
}

#blockcart-modal .modal-header {
    background: #fff
}

#blockcart-modal .modal-header .close {
    opacity: 1
}

#blockcart-modal .modal-header .close .material-icons {
    color: #363a42
}

#blockcart-modal .modal-body {
    padding: 50px 30px;
    background: #fff
}

#blockcart-modal .modal-body .divide-right span {
    display: inline-block;
    margin-bottom: 25px
}

@media (max-width: 767px) {
    #blockcart-modal .modal-body .divide-right span {
        display:block;
        padding: 0 8px
    }
}

#blockcart-modal .modal-dialog {
    width: 100%;
    max-width: 1140px;
    margin: 130px auto;
}

#blockcart-modal .product-image {
    display: block;
    width: 100%;
    max-width: 150px;
    margin: 0 0 0 auto
}

#blockcart-modal .modal-title {
    font-size: 16px;
    font-weight: 400;
    color: #353943;
    margin: 0 auto;
}

#blockcart-modal .modal-title i.material-icons {
    margin-right: 30px;
    color: #4cbb6c
}

#blockcart-modal .product-name {
    font-size: 18px;
    color: #1B1D37
}

@media (max-width: 767px) {
    #blockcart-modal .product-name {
        padding:0 8px
    }
}

#blockcart-modal .product-price {
    display: block;
    color: #363a42
}

@media (max-width: 767px) {
    #blockcart-modal .product-price {
        padding:0 8px
    }
}

#blockcart-modal .cart-content {
    background: #f7f7f7;
    padding: 10px;
}

#blockcart-modal .cart-content .btn {
    margin-bottom: 10px
}

#blockcart-modal .cart-content p,#blockcart-modal .cart-content .page-content.page-cms ul,.page-content.page-cms #blockcart-modal .cart-content ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 8px;
    color: #363a42
}

#blockcart-modal .cart-content p.product-total,#blockcart-modal .cart-content .page-content.page-cms ul.product-total,.page-content.page-cms #blockcart-modal .cart-content ul.product-total {
    padding: 8px;
    background-color: #f6f6f6
}

#blockcart-modal .cart-content p.cart-products-count,#blockcart-modal .cart-content .page-content.page-cms ul.cart-products-count,.page-content.page-cms #blockcart-modal .cart-content ul.cart-products-count {
    font-size: 16px;
    font-weight: 600;
    color: #6c868e
}

#blockcart-modal .cart-content p.product-tax,#blockcart-modal .cart-content .page-content.page-cms ul.product-tax,.page-content.page-cms #blockcart-modal .cart-content ul.product-tax {
    display: inherit;
    font-size: 14px
}

#blockcart-modal .cart-content p .label,#blockcart-modal .cart-content .page-content.page-cms ul .label,.page-content.page-cms #blockcart-modal .cart-content ul .label,#blockcart-modal .cart-content p .value,#blockcart-modal .cart-content .page-content.page-cms ul .value,.page-content.page-cms #blockcart-modal .cart-content ul .value {
    font-weight: 600
}

#blockcart-modal .cart-content .cart-content-btn {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

#blockcart-modal .cart-content .cart-content-btn button {
    margin-right: 14.4px
}

#blockcart-modal .cart-content .cart-content-btn .btn {
    white-space: inherit
}

#blockcart-modal .divide-right {
    border-right: 1px solid #dbdbdb
}
;@import "sass-embedded-legacy-load-done:43101";