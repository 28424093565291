//
// Variables Lodesse
//


//
// Lodesse Desktop design - Grid breakpoints
//

// Desktop : 1920px - 16columns - col/gap=60px - gutter=50px
// Mobile :
// Grid containers 420px | 1194px | 1920px -> 12px+12px 1944px

// Bulma - Grid // The container horizontal gap, which acts as the offset for breakpoints
$gap: 60px;
$spacer: 80px;
$spacer-min: 40px;

// Bulma - 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 960px + 2 * $gap; // 769px;
$desktop: 1152px + 2 * $gap;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Bootstrap - Grid
$grid-columns:                16;
$grid-gutter-width:           3.125rem; // 50px
$grid-row-columns:            6; // new in BS5

$grid-breakpoints: ( 
  xs: 0,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1400px,
  xxl: 1920px
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

$container-max-widths: (  
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1400px,
  xxl: 1920px
);

// Container padding // new in BS5
$container-padding-x: $grid-gutter-width;

//
// Design Colors (from homepage notice)
//

// Design Text
$blackcat: #412c3d;
$carbongrey: #665663;

// Design Primary
$almond: #f1e0d0;
$snow: #f9f9f9;
$white: #FFFFFF;

// Design Secondary
$lightapricot: #FAD6B9;
$mediumorchid: #A856A2;
$darklavender: #6C519C;
$tropicalblue: #C2E2F1;

$darkmint: #54BC81; // green
$malibu: #70CFEE; // cyan
$bluebayoux: #466E7E;
$pastelmagenta: #F59DB8; // rose light

$crandberry: #DA597D; // rose dark
$fadedorange: #F79550;
$parchment: #FFFCB4; // yellow light
$moonraker: #D8D2E9; // violet light

$bubblegum: #F8C3DA; // rose strange
$bleudefrance: #3B8AE6;
$watermelonpink: #F27179;
$eucalyptus: #2E7B54; // green dark

// Design Products
$lightgray: #DDD8DB;

//
// BS Colors
//
$primary:       $blackcat; // + blackcat
$secondary:     $white; // + blackcat

$success:       $eucalyptus;
$info:          $malibu;
$warning:       $parchment;
$danger:        $crandberry;
$light:         $lightgray;
$dark:          $darklavender;

// Map
$colors: (
    "blackcat":      $blackcat,
    "carbongrey":    $carbongrey,
    "almond":        $almond,
    "snow":          $snow,
    "lightapricot":  $lightapricot,
    "mediumorchid":  $mediumorchid,
    "darklavender":  $darklavender,
    "tropicalblue":  $tropicalblue,
    "darkmint":      $darkmint,
    "bluebayoux":    $bluebayoux,
    "pastelmagenta": $pastelmagenta,
    "crandberry":    $crandberry,
    "fadedorange":   $fadedorange,
    "parchment":     $parchment,
    "moonraker":     $moonraker,
    "bubblegum":     $bubblegum,
    "bleudefrance":  $bleudefrance,
    "watermelonpink":$watermelonpink,
    "eucalyptus":    $eucalyptus,
    "lightgray":     $lightgray,
    "blue":       $bluebayoux,
    "indigo":     $bleudefrance,
    "purple":     $moonraker,
    "pink":       $pastelmagenta,
    "red":        $crandberry,
    "orange":     $fadedorange,
    "yellow":     $parchment,
    "green":      $darkmint,
    "teal":       $lightapricot,
    "cyan":       $malibu,
    "white":      $white,
    "gray":       $snow,
    "gray-dark":  $lightgray
);

$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
);

// Options
$enable-caret:                true;
$enable-rounded:              false;
$enable-shadows:              false;

// Var prefix
$prefix:bs-;

// Body
$body-bg:                   $snow;
$body-color:                $carbongrey;

// Fonts
$font-family-sans-serif:      'IBM Plex Mono', -apple-system, Arial, Helvetica, sans-serif;
$headings-font-family:        'Libre baskerville', -apple-system, Arial, Helvetica, sans-serif;
$headings-font-weight:        400;
$libre-baskerville:        'Libre baskerville', -apple-system, Arial, Helvetica, sans-serif;

// Links
$link-color:                              $blackcat;
$link-decoration:                         none;
$link-shade-percentage:                   20% !default;
$link-hover-color:                        shift-color($link-color, $link-shade-percentage) !default;
$link-hover-decoration:                   none;

$stretched-link-pseudo-element:           after !default;
$stretched-link-z-index:                  1 !default;

// Borders
$border-width:                1px;
$border-style:                solid;
$border-color:                $blackcat;
$border-color-translucent:    rgba($blackcat, .175);

// Typo
$font-size-root:              null;
$font-size-base:              1rem; // Assumes the browser default, typically `16px`

// Warning !! Error in notice design pt = px !!!

// $font-size-sm:                $font-size-base * .875; // 11pt/14px
// $font-size-lg:                $font-size-base * 1.5; // 18pt/24px

// $h1-font-size:                $font-size-base * 3.33; // 40pt/53px
// $h2-font-size:                $font-size-base * 2.188; // 26pt/35px
// $h3-font-size:                $font-size-base * 1.5; // 18pt/24px
// $h4-font-size:                $font-size-base * 1.5; // 18pt/24px
// $h5-font-size:                $font-size-base * 1.5; // 18pt/24px
// $h6-font-size:                $font-size-base; // 12pt/16px

// Fix Error
$font-size-sm:                $font-size-base * .75; // 12px
$font-size-md:                $font-size-base * .875; // 11pt/14px
$font-size-lg:                $font-size-base * 1.125; // 18px

//$h1-font-size:                $font-size-base * 2.5; // 40px
$h1-font-size:                $font-size-base * 1.625; // 26px
$h2-font-size:                $font-size-base * 1.625; // 26px
$h3-font-size:                $font-size-base * 1.125; // 18px
$h4-font-size:                $font-size-base * 1.125; // 18px
$h5-font-size:                $font-size-base * 1.125; // 18px
$h6-font-size:                $font-size-base; // 16px

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size
);


// Input + Buttons
$input-btn-focus-box-shadow: 0;
$input-btn-border-width: 2px;
$btn-border-width: 2px;
$btn-border-radius: 0;

$btn-primary-color:     $blackcat;
$btn-secondary-color:   $lightgray;

//@use "sass: color";
$default-icon-color: var(--bs-primary);


// Box-shadow-variables
$box-shadow:                  none;
$box-shadow-sm:               none;
$box-shadow-lg:               none;
$box-shadow-inset:            none;
;@import "sass-embedded-legacy-load-done:42865";