.videosslider-container{
    display: block;
    width: 100%;
    position: relative;

    .swiper{
        position: initial;
    }
    .swiper-slide {
        overflow: hidden;
        height: 95vh;
    }
    .swiper-slide-video{
        width: 100%;
        height: 100%;
        max-height: 95vh;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .slide-text{
        position: absolute;
        left: 0;
        bottom: 100px;
        width: 100%;
        display: flex;
    }
    .caption{
        width: 33%;
        .ontitle{            
            color: $blackcat;
            text-transform: uppercase;
            font-size: 15px;
            line-height: 25px;
        }
        .title{            
            color: $blackcat;
            font-size: 33px;
            font-weight: normal;
            line-height: 40px;
            margin: 20px 0;         
        }
        .description{         
            color: $blackcat;
            font-size: 15px;
            font-weight: normal;
            line-height: 25px;
        }
        .url{         
            color: $blackcat;
            position: relative;
            font-family: $libre-baskerville;
            font-style: italic;
            display: block;
            margin: 0;
            padding: 0;
            font-size: 15px;   
            text-decoration: underline;
            &::before{            
                content: " ";    
                background-image: url(../img/svg/icon-arrowb.svg);
                background-color: transparent;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                width: 24px;
                height: 15px;
                border: 0;
                float: left;
                margin-right: 10px;
                margin-top: 5px;
            }
        }
    }
    .swiper-button-prev{
        left: 50px;
        &::after{
            background-image: url(../img/svg/icon-arrowl.svg);
            content: " ";
            width: 30px;
            height: 10px;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
    .swiper-button-next{
        right: 50px;
        &::after{
            background-image: url(../img/svg/icon-arrowr.svg);
            content: " ";
            width: 30px;
            height: 10px;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
    .swiper-pagination{
        position: absolute;
        right: 50px;
        bottom: 50px;
        width: auto;
        left: auto;
        .swiper-pagination-bullet{
            color: $blackcat;
            background: transparent;
            font-size: 17px;
            margin: 0 10px;
            width: auto;
            opacity: 0.6;
            
            &.swiper-pagination-bullet-active{
                opacity: 1;
            }
        }
    }
    
    .align-left{
        .caption{       
            margin-left: 0%;
        }
    } 
    .align-right{
        .caption{       
            margin-left: 66%;
        }
    } 
    .align-middle{
        .caption{       
            margin-left: 33%;
        }        
    } 

    /*.swiper{
        display: inline-block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
    .swiper-wrapper{
        display: inline-block;
        width: 100%;
    }
    .swiper-slide{
        display: none;
        width: 100%;
        &.swiper-slide-active{
            display: inline-block;
        }
    }*/
}

.header-light{
    .videosslider-container{    
        .caption{                
            .ontitle{            
                color: $blackcat;
            }
            .title{            
                color: $blackcat;      
            }
            .description{         
                color: $blackcat;
            }
            .url{         
                color: $blackcat;
                &::before{      
                    background-image: url(../img/svg/icon-arrowb.svg);     
                }
            }
        }
        .swiper-button-prev{
            &::after{
                background-image: url(../img/svg/icon-arrowl.svg);
            }
        }
        .swiper-button-next{
            &::after{
                background-image: url(../img/svg/icon-arrowr.svg);
            }
        }
        .swiper-pagination{
            .swiper-pagination-bullet{
                color: $blackcat;
            }
        }
    }
}

.header-dark{
    .videosslider-container{
        .caption{                
            .ontitle{            
                color: $white;
            }
            .title{            
                color: $white;      
            }
            .description{         
                color: $white;
            }
            .description p{         
                color: $white;
            }
            .url{         
                color: $white;
                &::before{           
                    background-image: url(../img/svg/icon-arrowb-w.svg);
                }
            }
        }
        .swiper-button-prev{
            &::after{
                background-image: url(../img/svg/icon-arrowl-w.svg);
            }
        }
        .swiper-button-next{
            &::after{
                background-image: url(../img/svg/icon-arrowr-w.svg);
            }
        }
        .swiper-pagination{
            .swiper-pagination-bullet{
                color: $white;
            }
        }
    }
}


// Tablet - One row
@include media-breakpoint-down(lg) {
    
}


// Mobile - Two row
@include media-breakpoint-down(md) {
    .videosslider-container{
    
        .swiper-slide-video{
            max-height: none;
        }
        .swiper-slide {
            height: 95vh;
        }
        .caption{
            width: 100%;
            .ontitle{            
                font-size: 13px;
                line-height: 15px;
            }
            .title{            
                font-size: 25px;
                line-height: 30px;       
            }
            .description{         
                font-size: 15px;
                font-weight: normal;
                line-height: 25px;
            }
            .url{         
                font-size: 13px;   
                &::before{
                    height: 13px;
                    margin-top: 0;
                }
            }
        }
        .swiper-button-prev{
            left: 15px;
        }
        .swiper-button-next{
            right: 15px;
        }
        
        .align-left, .align-right, .align-middle{
            .caption{       
                margin-left: 0%;
            }
        } 
        .swiper-pagination{
            display: none;
        }
    }
}
;@import "sass-embedded-legacy-load-done:43071";