.imageslist{
    display: inline-block;
    padding: 80px 0;
    width: 100%;
    position: relative;

    .ontitle{
        text-align: center;
        text-transform: uppercase;
        font-size: 14px;
        display: block;
    }
    h3{        
        text-align: center;
        font-family: $libre-baskerville;
        color: $blackcat;        
        line-height: 40px;
        font-size: 30px;
        text-align: center; 
        margin: 0 0 40px;
    }
        
    .swiper-button-prev{
        display: none;
    }
    .swiper-button-next{
        display: none;
    }
    .swiper-button-disabled{
        display: none;
    }

}

.imageslist1-container{    
    border-bottom: $border-width solid $blackcat;

    .list{            
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: top;
        gap: 1%;

        .content-element{
            width: 30%;
            img{
                width: 100%;
                display: inline-block;
                height: auto;
            }
            .caption{
                width: 100%;
                display: inline-block;
                margin-top: 40px;
                .title{
                    margin: 0;
                    font-family: var(--bs-font-sans-serif);
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: normal;
                }
                .description{
                    margin: 0;
                    font-family: $libre-baskerville;
                    font-size: 22px;
                    line-height: 25px;
                    margin-top: 10px;
                    p{
                        margin: 0;
                        font-family: $libre-baskerville;
                        font-size: 22px;
                        line-height: 25px;
                        margin-top: 10px;
                    }
                    a{
                        font-size: 14px;
                        text-decoration: underline;
                    }
                }                
                p{
                    margin: 0;  
                }
            }
        }
    }
}
#module-divioseo_tutorials-tutorials{    
    .imageslist1-container{    
        border: 0;
    }
}

.swiper-button-next,
.swiper-button-prev{
    display: flex;
}
.swiper-button-disabled{
    display: none;
}

.imageslist2-container{
    .list{            
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: top;
        gap: 1%;

        .content-element{
            width: 30%;
            img{
                width: 100%;
                display: inline-block;
                height: auto;
            }
            .caption{
                width: 100%;
                display: inline-block;
                margin-top: 0;
                .title{
                    margin: 0;
                    font-family: $libre-baskerville;
                    font-size: 22px;
                    line-height: 25px;
                    text-align: center;
                    font-weight: normal;
                }
                .description{
                    margin: 0;
                    margin-top: 20px;
                    font-size: 14px;
                    text-align: center;
                    a{
                        text-decoration: underline;
                    }
                }                
                p{
                    margin: 0;  
                }
            }
        }
    }
}


.philosophy-container{
    .list{            
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: top;
        gap: 1%;

        .content-element{
            width: 30%;
            text-align: center;
            max-width: 330px;
            img{
                width: 100%;
                display: inline-block;
                height: auto;
                max-width: 300px;
                margin: 0 auto;
            }
            .caption{
                width: 100%;
                display: inline-block;
                margin-top: 0;
                .title{
                    margin: 0;
                    font-family: $libre-baskerville;
                    font-size: 22px;
                    line-height: 25px;
                    text-align: center;
                    font-weight: normal;
                }
                .description{
                    margin: 0;
                    margin-top: 20px;
                    font-size: 14px;
                    text-align: center;
                    a{
                        text-decoration: underline;
                    }
                }                
                p{
                    margin: 0;  
                }
            }
        }
    }
}

.imageslist3-container{
    background-color: $white;
    
    h3.title{        
        text-align: center;
        font-family: $libre-baskerville;
        color: $blackcat;        
        line-height: 40px;
        font-size: 30px;
        text-align: center;
        margin: 0;
    }
    .description{
        text-align: center;
        font-size: 16px;
        display: block;
        max-width: 800px;
        margin: 20px auto;
    }

    .list.actifs-list{            
        display: inline-block;
        width: 100%;
        margin: 30px 0;

        .content-element.actif-element{
            width: 100%;
            display: inline-block;
            text-align: left;            
            h4.title{
                text-transform: uppercase;
                color: $blackcat;
                margin: 0 0 20px;
                sup{
                    font-size: 11px;
                    margin-left: 10px;
                }
            }
            .actif-content{
                display: flex;
                gap: 20px;
                justify-content: space-between;
                align-items: start;
                margin-bottom: 40px;

                .table{
                    width: 70%;
                    display: flex;
                    gap: 10px;
                    border-top: $border-width solid $blackcat;
                    border-bottom: $border-width solid $blackcat;
                    padding: 0;
                    margin: 0;
                    li{
                        list-style: circle;
                        margin-left: 20px;
                    }
                    .left{
                        padding: 25px 25px 25px 0px;
                        font-size: 14px;
                        width: 30%;
                        font-family: $libre-baskerville;
                        .title-mobile{
                            display: none;
                        }
                        a{
                            padding: 0;
                            margin: 10px 0 0;
                            text-decoration: underline;
                            display: block;
                        }
                    }
                    .right{
                        padding: 25px 0px 25px 25px;
                        font-size: 12px;
                        border-left: $border-width solid $blackcat;
                        width: 70%;
                        .title-extra{
                            
                            &::before{            
                                content: " ";    
                                background-image: url(../img/svg/icon-infinite.svg);
                                background-color: transparent;
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: contain;
                                width: 12px;
                                height: 10px;
                                border: 0;
                                float: left;
                                margin-right: 5px;
                                margin-top: 5px;
                            }
                        }
                        .content-extra{
                            padding: 10px 50px;
                        }
                    }
                }

                .image{
                    max-width: 20%;
                    border-radius: 150px;
                    width: 200px;
                    height: 200px;
                    overflow: hidden;
                    display: flex;
                    background-color: $white;
                    img{    
                        width: 100%;
                        height: auto;
                        &.default{
                            display: inline-block;
                        }
                        &.hover{
                            display: none;
                        }
                    }
                    
                    &:hover{
                        img{   
                            &.default{
                                display: none;
                            }
                            &.hover{
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
    

    .actifs-link{                
        color: $blackcat;
        position: relative;
        font-style: italic;
        display: block;
        margin: 0;
        padding: 0;
        font-size: 15px;   
        text-decoration: underline;
        &::before{            
            content: " ";    
            background-image: url(../img/svg/icon-arrowb.svg);
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 24px;
            height: 15px;
            border: 0;
            float: left;
            margin-right: 10px;
            margin-top: 5px;
        }
    }
}


.imageslistproduct-container{
    
    background-color: $snow;
    
    h2.title{        
        text-align: center;
        font-family: $libre-baskerville;
        color: $blackcat;        
        line-height: 40px;
        font-size: 30px;
        text-align: center;
        margin: 0;
    }
    .description{
        text-align: center;
        font-size: 16px;
        display: block;
        max-width: 800px;
        margin: 20px auto;
    }
    .list{            
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: top;
        gap: 1%;

        .content-element{
            width: 30%;
            .index{
                font-family: $libre-baskerville;
                font-size: 80px;
                text-align: center;
                line-height: 80px;
                margin-top: 20px;
            }
            .caption{
                width: 100%;
                display: inline-block;
                margin: 0 0 25px;
                text-align: center;
                .title{
                    margin: 0 auto;
                    font-size: 14px;
                    line-height: 25px;
                    text-align: center;
                    text-transform: uppercase;
                    max-width: 300px;
                }
                img{
                    max-width: 250px;
                    display: inline-block;
                    height: auto;
                }
                .description{
                    margin: 0;
                    margin-top: 20px;
                    font-size: 14px;
                    text-align: center;
                    a{
                        text-decoration: underline;
                    }
                }                
                p{
                    margin: 0;  
                }
            }
        }
    }
}


.single-accompaniement{

    h2,h3,h4,h5,h6{
        font-family: "Libre baskerville", -apple-system, Arial, Helvetica, sans-serif;
        /*font-weight: 600;*/
    }
    
    .accompaniement-single-text{
        display: inline-block;
        width: 100%;
        margin: 40px 0;
    
        .accompaniement-single-text-col{
            align-items: center;
            display: flex;
            flex-wrap: wrap;
        }
        .accompaniement-single-img{
            margin: 0 auto;
            display: block;
            width: 100%;
        }
        .accompaniement-single-img-min{
            max-width: 270px;
        }
    }
    .accompaniement-single-products{
        display: inline-block;
        width: 100%;
        margin: 80px 0;
    
        h3{
            padding: 0 0 20px;
            margin: 0 0 20px;
            border-bottom: 1px solid $blackcat;
            font-size: 20px;
            display: flex;
            justify-content: space-between;
            .price{
                /*float: right;*/
            }
        }
        .time,
        .details,
        .button-default {
            margin: 15px 0;
        }
    }
    .imageslist1-container{
        border-top: 1px solid $blackcat;
        border-bottom: 0;
    }
}

.single-asset{
    display: inline-block;
    width: 100%;
    .details{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: normal;
        .left{
            width: 50%;
            padding-right: 80px;    
            border-right: 1px solid;
            h1{
                display: inline-block;
                margin: 0 0 120px;
                width: 100%;
            }
            h2{
                border: 1px solid;
                display: inline-block;
                padding: 15px;
                font-size: 16px;
                border-radius: 15px;
                font-family: "IBM Plex Mono";
                font-weight: 500;
                text-transform: uppercase;
            }
            .description{
                width: 50%;
                font-size: 14px;
                margin: 10px 0;
            }
            .button-default{
                margin: 10px 0;
            }
        }
        .right{
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 100%;
                border-radius: 500px;
                max-width: 400px;
                margin: 60px 0;
            }
        }
    }
}

@include media-breakpoint-down(xl) {  
    .imageslist1-container{    
    }
}

// Mobile
@include media-breakpoint-down(md) {

    .imageslist{
        .list{
            flex-flow: inherit;
            &.swiper-wrapper{
                display: flex;
                flex-wrap: nowrap;
                height: 100%;
                justify-content: space-between;
            }
            .swiper-slide{
                width: 100%;
            }
        }
        
        .swiper-button-prev{
            background: transparent;      
            width: 30px;
            height: 50px;
            border-radius: 0;
            border: 0;
            top: 50%;
            left: 30px;
            display: flex;
            &::after{
                background-image: url(../img/svg/icon-arrowl.svg);
                content: " ";
                width: 30px;
                height: 10px;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
        .swiper-button-next{
            background: transparent;      
            width: 30px;
            height: 50px;
            border-radius: 0;
            border: 0;
            top: 50%;
            right: 30px;
            display: flex;
            &::after{
                background-image: url(../img/svg/icon-arrowr.svg);
                content: " ";
                width: 30px;
                height: 10px;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }

    .imageslist3-container{
        background-color: $white;
        
        h3.title{        
            text-align: center;
            font-family: $libre-baskerville;
            color: $blackcat;        
            line-height: 40px;
            font-size: 30px;
            text-align: center;
            margin: 0;
        }
        .description{
            text-align: center;
            font-size: 16px;
            display: block;
            max-width: 800px;
            margin: 20px auto;
        }
    
        .list.actifs-list{    
    
            .content-element.actif-element{         
                h4.title{
                    display: none;
                }
                .actif-content{
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    .table{
                        order: 1;
                        width: 100%;
                        border: 0;
                        .left{
                            width: 100%;
                            text-align: center;
                            padding: 25px 50px;
                                 
                            .title-mobile{
                                display: inline-block;
                                width: 100%;
                                text-transform: uppercase;                                
                                color: $blackcat;
                                text-align: center;
                                font-family: $libre-baskerville;
                                margin-bottom: 20px;
                                font-size: 16px;
                                sup{
                                    font-size: 11px;
                                    margin-left: 10px;
                                }
                            }
                        }
                        .right{
                            display: none;
                        }
                    }
                    .image{
                        order: 0;
                        max-width: 100%;
                    }
                }
            }
        }
    }

    .single-accompaniement{        
        .accompaniement-single-text-reverse{
            .row{
                display: flex;
                .accompaniement-single-text-col:first-child{
                    order: 1;
                }
                .accompaniement-single-text-col:last-child{
                    order: 0;
                }
            }
        }
        .accompaniement-single-product {
            margin: 0 0 40px;
            h3, .h3 {
                font-size: 18px;
            }
        }
    }

    

    .single-asset{
        .details{
            .left{
                order: 1;
                width: 100%;
                padding-right: 0;    
                border-right: 0;
                h1{
                    margin: 0 0 80px;
                    width: 100%;
                }
                h2{
                }
                .description{
                    width: 80%;
                }
            }
            .right{
                order: 0;
                width: 100%;
                img{
                }
            }
        }
    }
}

// Mobile
@include media-breakpoint-down(sm) {
    
    .imageslist{
        h3.title, h3{
            font-size: 18px;
            line-height: 25px;
            margin: 0 0 20px;
        }        
    }
    .imageslist1-container {
        .list {
            .content-element {
                .caption {
                    .description {
                        p {
                            margin: 0;
                            font-family: "Libre baskerville", -apple-system, Arial, Helvetica, sans-serif;
                            font-size: 18px;
                            line-height: 25px;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }

}
;@import "sass-embedded-legacy-load-done:43077";