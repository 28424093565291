
h1,h2,h3,h4,h5,h6{
    font-family: "Libre baskerville", -apple-system, Arial, Helvetica, sans-serif;
    /*font-weight: 600;*/
    margin: 20px 0 10px;
    color:  $blackcat;
}

p{
    font-size: $font-size-md;
    margin-bottom: 5px;
    color:  $blackcat;
}

main{
    ul, ol{
        padding-left: 20px;
        margin: 10px 0;
    }
    ul{
        li{
            position: relative;
            margin-bottom: 5px;
            &::before{
                content: " ";
                background-image: url(../img/svg/icon-infinite.svg);
                position: absolute;
                background-repeat: no-repeat;
                width: 20px;
                height: 20px;
                left: -20px;
                top: 7px;
            }
        }
    }
}
.nav{
    &.nav-tabs{
        >li{
            &::before{
                display: none;
            }
        }
    }
}

.text-center{
    text-align: center;
}

.text-right{
    text-align: right;
}

.button-default{
    color: $blackcat;
    position: relative;
    font-style: italic;
    display: inline-block;
    margin: 50px 0;
    padding: 0;
    font-size: 15px;
    text-decoration: underline;
    &::before {
        content: " ";
        background-image: url(../img/svg/icon-arrowb.svg);
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 24px;
        height: 15px;
        border: 0;
        float: left;
        margin-right: 10px;
        margin-top: 5px;
    }
}
;@import "sass-embedded-legacy-load-done:43039";