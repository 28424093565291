.testimonials-container{
    display: inline-block;
    padding: 80px 0;
    width: 100%;
    .slide-text{
        .testimony-name{    
            text-align: center;
            text-transform: uppercase;
            font-size: 16px;
        }
        .testimony-message{            
            text-align: center;
            font-family: $libre-baskerville;
            color: $blackcat;       
            text-align: center;
            max-width: 450px;
            margin: 100px auto;
            font-style: italic;
            p{ 
                line-height: 40px;
                font-size: 30px;
            }
        }
        .testimony-job{
            text-align: center;
            font-size: 16px;
        }
    }
}
;@import "sass-embedded-legacy-load-done:43083";