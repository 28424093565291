.container-single-product{
    .product__images{
        position: relative;
    }
    .swiper-product-gallery{
        .swiper-wrapper{
            flex-wrap: wrap;
            .swiper-slide{
                img{
                    width: 100%;
                    margin: 10px 0;
                }
            }
        }
    }
    .swiper-product-pagination{
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: auto;
        left: auto;
        display: none;
        z-index: 10;
        .swiper-pagination-bullet{
            color: $blackcat;
            background: transparent;
            font-size: 17px;
            margin: 0 10px;
            width: auto;
            opacity: 0.6;
            
            &.swiper-pagination-bullet-active{
                opacity: 1;
            }
        }
    }
}



@include media-breakpoint-down(lg) {
    .container-single-product{
        .swiper-product-gallery{
            .swiper-wrapper{
                flex-wrap: nowrap;
                .swiper-slide{
                    img{
                        margin: 0;
                    }
                }
            }
        }
        .swiper-product-pagination{
            display: inline-block;
        }
    }
}

@include media-breakpoint-down(md) {

}

@include media-breakpoint-down(sm) {

}

;@import "sass-embedded-legacy-load-done:43099";