
/* CHECKOUT */
.checkout__steps{
    justify-content: center;
    display: flex;
    padding: 30px 0;
    margin-bottom: 80px;
    flex-wrap: wrap;

    .checkout__steps__mobile {
        line-height: 5px;
    }

    .checkout__steps__list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1400px;

        .checkout__steps__item {
            &::before{
                display: none;
            }
            .checkout__steps__number {
                font-size: 20px;
                font-family: $libre-baskerville;
                color: $blackcat;
            }
            .checkout__steps__text {
                color: $blackcat;
                font-size: 13px;
            }
        }
    }
}

#checkout{
    .cart-grid-body{

        .step__title{
            .h3{
                margin: 0 0 20px;
                padding: 0;
                line-height: 30px;
                text-transform: uppercase;
                display: inline-block;
                width: 100%;
                font-weight: 500;
            }
        }
        .step__content{
            background: $white;
            padding: 40px;
            border: 1px solid #bfbfbf;
            margin: 0;
            .nav-tabs{
                margin: 0 0 20px 0 !important;
                border: 0;
                padding: 0 !important;
                .nav-item{
                    &::before{
                        display: none;
                    }
                    .nav-link{
                        padding: 0 0 0 30px;
                        margin: 0 30px 5px 0;
                        background: transparent;
                        border: 0;
                        font-weight: normal;
                        font-size: 14px;
                        border-left: 1px solid;
                        &.active{
                            background: transparent;
                            text-decoration: underline;
                        }
                    }                
                    &:first-child{                        
                        .nav-link{
                            padding: 0;
                            border: 0;
                        }
                    }
                }
            }
        }
    }     
    .cart-grid-right{
        .accordion-item{
            border: 1px solid #bfbfbf;
        }
        .accordion-button{
            padding: 20px 40px !important;
            margin: 0 !important;
        }
        .accordion-collapse{
            padding: 40px;
            margin: 0 !important;
            .card-block{
                .cart-summary__products{
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    margin-bottom: 20px;
                    .collapse {
                        width: 100%;
                    }
                }
            }
            .cart-summary__product__list{
                margin: 0;
                padding: 0;
                .cart-summary__product{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    justify-content: space-between;
                    font-size: 13px;
                    &::before{
                        display: none;
                    }
                    .cart-summary__product__image{
                        margin: 0;
                        padding: 0;
                    }
                    .cart-summary__product__body{
                        padding: 0 10px;
                        margin: 0;
                    }
                    .cart-summary__product__price{
                        margin: 0;
                        padding: 0;
                    }
                }
                
            }
            
        }
        
        .cart-summary__line{
            display: flex;
            justify-content: space-between;
            margin: 0 0 20px;
        }
        
        .cart-summary__totals{
            .cart-summary__line{
                &.cart-total{
                    display: flex;
                    justify-content: space-between;
                    margin: 20px 0 0;
                    border-top: 1px solid;
                    padding-top: 20px;
                    font-weight: bold;
                }
            }
        }
    }   
}

form{
    .mb-3{
        margin-bottom: 20px !important;
    }
    .form-label{
        margin-bottom: 0.5rem;
        font-size: 14px;
        font-weight: normal;
        color: $blackcat;
    }
    .form-control{
        border: 1px solid #ced4da;
    }
    .password-field{
        .btn {
            border: 1px solid #ced4da;
            color: $blackcat;
        }
        
    }
    .login__forgot-password{
        margin: 0 !important;
        float: right;
    }
}

#delivery-addresses,
.addresses{
    .card-body{
        margin: 10px 0;
        padding: 20px;
        width: 100%;
        .card-title{
            margin: 0;
            font-size: 16px;
            margin: 0 0 10px;
        }
        .address__content{
            font-size: 13px;
        }
    }
    .address__actions{
        padding: 20px;
        display: flex;
        justify-content: space-between;
        .address__delete{
            color: red;
            margin-left: 10px;
        }
    }
}
#delivery-addresses{
    .address__actions{
        padding: 0;
    }
}

.checkout-navigation{
    display: flex;
    justify-content: space-between;
    .nav-left,
    .nav-right{
        width: 40% !important;
        margin: 0;
        padding: 0;
        border: 0;
        text-align: left;
        display: flex;
        align-items: center;
    }
    .nav-right{
        justify-content: end;
    }
}

.payment__list{
    .payment__definitions {
        padding: 20px !important;
        margin: 0 0 30px !important;
        background: #f9f9f9;
    }
}

.order-confirmation__details{
    .card-body{
        padding: 40px;
        hr {
            margin: 40px 0;
        }
    }
}

// Tablet - One row
@include media-breakpoint-down(lg) {
    
}


// Mobile - Two row
@include media-breakpoint-down(md) {


    .checkout-navigation{
        .nav-left,
        .nav-right{
            font-size: 12px;
            margin: 10px 0 !important;
        }
        .nav-right{
            text-align: right;
        }
    }
    .checkout__steps{    
        .checkout__steps__list {
            display: none;
        }
    }
}

;@import "sass-embedded-legacy-load-done:43103";