.tutorials-container{
    display: inline-block;
    width: 100%;
}
.tutorials-info{
    display: inline-block;
    width: 100%;
    text-align: right;
}
.tutorials-list{
    display: inline-block;
    width: 100%;
    position: relative;
    .tutorial-element{
        display: flex;
        width: 100%;
        flex-wrap: nowrap;
        gap: 50px;
        justify-content: space-between;
        margin: 80px auto;
        max-width: 1200px;
        .video-details{
            max-width: 100%;
            width: 600px;
            .title{
                margin: 0 0 20px;
                border-bottom: 1px solid;
                padding: 0 0 20px;
            }
            .description{
                margin-top: 15px;
                margin-bottom: 15px;
            }
            .button-default{
                margin: 0;
            }
        }
        .video-screen{
            width: 400px;
            max-width: 100%;
            position: relative;
            cursor: pointer;
            &::before{
                content: " ";    
                background-image: url(../img/svg/media-play-w.svg);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                position: absolute;
                left: 40%;
                top: 40%;
                width: 20%;
                height: 20%;
            }
            img{
                width: 100%;
            }
        }
    }
}
.tutorials-separator{
    border-top: 1px solid  $blackcat;
}

// Tablet
@include media-breakpoint-down(md) {    

}

// Mobile
@include media-breakpoint-down(sm) {   
    .tutorials-list{
        .tutorial-element{
            flex-wrap: wrap;
            .video-details{
                order: 1;
                width: 100%;
            }
            .video-screen{
                order: 0;
                width: 100%;
            }
        }
    }
}
;@import "sass-embedded-legacy-load-done:43091";