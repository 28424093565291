/* ACCOUNT */
#registration,
#authentication,
#password{
    #content-wrapper{
        background: $white;
        padding: 40px;
        margin: 40px auto;
        max-width: 800px;
        .h4{
            margin: 0 0 20px;
            padding: 0;
            line-height: 30px;
            text-transform: uppercase;
            display: inline-block;
            width: 100%;
        }
        hr {
            margin: 40px 0;
        }
        #back-to-login{
            margin: 0;
            padding: 0;
            border: 0;
        }
    }
    .login__forgot-password{
        margin: 0 !important;
        width: 100%;
        display: inline-block;
        float: none;
    }
}

.page-customer{
    display: inline-block;
    width: 100%;
    .customer-link__logout{
        display: block;
        margin: 50px auto 0;
        text-align: center;
        font-size: 15px;
        color: red;
    }
    .customer-link{
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        .link-item{
            background: $white;
            margin: 10px;
            padding: 25px;
            justify-content: center;
            align-items: center;
            border: 1px solid #bfbfbf;
            border-radius: 10px;
            text-align: center;
            min-height: 190px;
            display: flex;
            flex-wrap: wrap;
        }
        a{
            margin: 0;
            padding: 0;
            &:hover{
                .link-item{
                    background: #bfbfbf;
                }
            }
            i{
                width: 100%;
                text-align: center;
                margin: 15px 0;
            }
        }
    }
}

.alert {
    ul{
        li{
            &::before{
                display: none;
            }
        }
    }
}

/* WISHLIST */
.wishlist-modal {
    .modal-body {
        padding: 0 1.875rem;
    }
}
.wishlist-list-item{
    &::before{
        display: none;
    }
}
.wishlist-footer-links {
    margin-bottom: 3.125rem;
    padding: 25px 0;
    display: flex;
    justify-content: space-between;
}

.account-menu{
    flex-wrap: wrap;
    background: #fff;
    padding: 40px;
    .account-menu__title{
        width: 100%;
    }
    .account-menu__line{
        width: 100%;
        padding: 25px 0;
        border-bottom: 1px solid #bfbfbf;
        margin-bottom: 5px;
        .link-item{
            display: flex;
            align-items: center;
            i{
                margin-right: 10px;
            }
        }
        &.account-menu--signout{
            border: 0;
            color: red;
            margin-bottom: 0;
            padding: 25px 0 0;
        }
        &.customer_account_links{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            a{
                width: 45%;
            }
        }
    }
}


@include media-breakpoint-down(md) {

    .page-customer{
        .customer-link{
            a{
                width: 100%;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:43105";