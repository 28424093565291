/* stylelint-disable */
@import "~bourbon/core/bourbon"; // https://www.bourbon.io/docs/latest/

// Variables + Mixin | Bootstrap
@import "~bootstrap/scss/functions";

@import "variables-lodesse.scss"; // Lodesse variables : Can override prestashop + Hummingbird + bootstrap variables
@import "mixins-lodesse.scss"; // theme mixins

@import "_reference/variables-default-prestashop.scss"; // Prestashop variables - With !default (reference)
@import "~bootstrap/scss/variables"; // Bootstrap variables - Or watch in abstract/variables-default-bootstrap5.1.scss
@import "_reference/variables-default-hummingbird.scss"; // Hummingbird variables - With !default (reference)

@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/utilities";

// Layout & components | Bootstrap // Optimize : We can comment or replace components by our
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
//@import "~bootstrap/scss/buttons"; // replace for reverse outline buttons
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/utilities/api";

@import "~bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css";

/* stylelint-enable */

;@import "sass-embedded-legacy-load-done:42767";