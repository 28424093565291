.hp-animation-1{    
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    min-height: 120px;
    padding: 10px 15px;
    overflow: hidden;
    background-color: var(--bs-snow);
    border-bottom: $border-width solid $blackcat;
    
    h1{
        margin: 0;
        font-weight: normal;
        font-size: $h1-font-size;
        line-height: 30px;
        text-align: center;
        left: 100%;
        position: relative;
        transition: all 3s ease-in-out;
        &.show{
            left: 0;
        }
    }
}

.hp-animation-2{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    min-height: 580px;

    h2{
        max-width: 640px;
        margin: 50px auto;
        text-align: center;
        line-height: 55px;
        font-size: 30px;
        span.text-top,
        span.text-bottom{
            display: block;
            margin: 25px 0; 
            span{
                opacity: 0;
                transition: all 1s ease-in-out;
                &.show{
                    opacity: 1;
                }
            }
        }
        i{
            text-decoration: underline;
        }
    }
}

.hp-animation-linear{    
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    min-height: 90px;

    background-color: var(--bs-almond);
    
    overflow: hidden;
    position: relative;
    .scroll {
        display: flex;
        width: 100%;

            
        div{
            margin: 0;
            font-weight: normal;
            font-family: $libre-baskerville;
            font-size: 16px;
            color: $blackcat;

            
            white-space: nowrap;
            animation: animate 40s -40s linear infinite;
            will-change: transform;
            
            &:nth-child(2) {
                animation: animate2 40s -20s linear infinite;
            }
        }
    }
    .infinite-separator{
        background-image: url(../img/svg/icon-infinite.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 12px;
        height: 10px;
        display: inline-block;
        margin: 0px 10px;
    }

    @keyframes animate {
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(-100%);
        }
      }
      
      @keyframes animate2 {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-200%);
        }
      }
}

.hp-animation-4 {
    .scroll{
        div{
            font-size: 20px;
        }
    }
}


// Tablet - One row
@include media-breakpoint-down(lg) {
    
}


// Mobile - Two row
@include media-breakpoint-down(md) {
    .hp-animation-1{            
        h1{
            font-size: 20px;
            line-height: 30px;
        }
    }
}

// Mobile - One row
@include media-breakpoint-down(sm) {
    .hp-animation-1{            
        h1{
            line-height: 25px;
            font-size: 17px;
            padding: 0 15px;
        }
    }
    
    .hp-animation-2{
        h2,  .h2 {
            line-height: 45px;
            font-size: 25px;
            padding: 0 20px;
        }
    }
}




;@import "sass-embedded-legacy-load-done:43073";