.footer__before{
  background-color: var(--bs-snow);
  padding-top: 0;
  padding-bottom: $spacer;
}
.footer__main{
  background-color: var(--bs-almond);
  padding-top: $spacer-min;
  padding-bottom: $spacer-min;
  border-top: 2px  solid $blackcat;

  .footer__main__top{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .footer-contact,
    .footer-pointsofsale,
    .email_subscription {
      width: 30%;
      color: $blackcat;
      font-size: 14px;

      h4{
        font-family: $headings-font-family;
        margin: 0 0 15px;
        font-weight: bold;
        color: $blackcat;
      }
      p{
        color: $blackcat;
        font-size: 14px;
      }
      a{
        text-decoration: underline;
      }
    }
    .email_subscription {
      
      .block_newsletter_alert{
        margin: 0 0 10px;
        padding: 10px;
        text-align: left;
        font-size: 12px;
      }
      .input-newsletter-c{
        position: relative;
        input[type=email] {
          display: inline-block;
          width: 100%;
          background-color: transparent;
          border: 0;
          border-bottom: 2px solid $blackcat;
          height: 25px;
          padding: 0;
          line-height: 25px;
        }
        input[type=email]::placeholder {
          color: $blackcat;
        }
        input[type=submit] {
          background-image: url(../img/svg/icon-arrowb.svg);
          background-color: transparent;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          right: 0;
          top: 0;
          font-size: 0;
          width: 30px;
          height: 25px;
          border: 0;
        }
      }
    }
  }
  .footer__main__bottom{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: $spacer;

    .footer-message{
      font-family: $headings-font-family;
      color: $blackcat;
      text-align: right;
      width: 100%;
      font-size: 20px;
      font-weight: 600;
      margin: 0 0 10px;
      p{        
        font-size: 18px;
        font-weight: normal;
      }
    }

    .link-block{
      display: flex;
      flex-wrap: wrap;
      width: 70%;
      color: $blackcat;
      margin: 0;
      gap: 40px;

      li{
        a{
          text-transform: uppercase;
          font-size: 14px;
        }
      }
    }

    .ps-social-follow{
      display: flex;
      flex-wrap: wrap;
      width: 30%;
      ul{
        display: flex;
        width: 100%;
        gap: 15px;
        justify-content: right;
        margin: 0;

        li{       
          background-repeat: no-repeat;
          background-position: center; 
          &.facebook{
            background-image: url(../img/svg/Facebook_Lodesse.svg);
          }
          &.instagram{
            background-image: url(../img/svg/Instagram_Lodesse.svg);
          }
          &.youtube{
            background-image: url(../img/svg/Youtube_Lodesse.svg);
          }
          a{
            &::before{
              content: "";
              width: 25px;
              height: 25px;
              display: inline-block;
            }
          }
        }
      }
    }
  }
  .copyright{
    font-size: 14px;
    font-style: italic;
  }

}

.button-backtop{
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 1;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  &::before{
      content: " ";
      background-image: url(../img/svg/icon-arrowl.svg);
      background-color: $white;
      background-repeat: no-repeat;
      background-position: center center;
      border: 2px solid $blackcat;
      width: 30px;
      height: 30px;
      display: inline-block;
      background-size: 25px;
      transform: rotate(90deg);
      border-radius: 50px;
      padding: 25px;
  }
  &.button-show{
    opacity: 1;
  }
}

// Tablet
@include media-breakpoint-down(lg) {
}

// Tablet
@include media-breakpoint-down(md) {
  
  .footer__main{
    .footer__main__bottom{
      .footer-message{
        font-size: 16px;
        p{        
          font-size: 16px;
        }
      }
    }
  }
}

// Mobile
@include media-breakpoint-down(sm) {
  
  .footer__main{
    .footer__main__bottom{
      .footer-message{
        text-align: center;
        width: 100%;
        font-size: 16px;
        p{        
          font-size: 16px;
        }
      }
    }
  }
  .footer__main{

    .footer__main__top{

      .footer-contact,
      .footer-pointsofsale,
      .email_subscription {
        width: 100%;
        margin-bottom: $spacer-min;
      }
    }

    .footer__main__bottom{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: $spacer;
  
      
      .link-block{
        width: 100%;
        justify-content: center;
        gap: 10px;
      }
  
      .ps-social-follow{
        ul {
          width: 100%;
          gap: 15px;
          justify-content: center;
          margin: 20px 0;
        }
      }
    }
  }
  
}
;@import "sass-embedded-legacy-load-done:43043";