.adtm_menu_container {
    padding: 0;
    margin: 0;
    display: inline-block;
    width: 100%;
    #adtm_menu{
        padding: 0;
        margin: 0;
        display: inline-block;
        width: 100%;
        background: transparent;
        border: 0;
        box-shadow: none;
        .li-niveau1 {
            min-height: auto;
            line-height: inherit;
            border: 0;
            margin: 0 15px;
            .a-niveau1{
                border: 0;
                padding: 0;
                margin: 0 5px;
                height: auto;
                min-height: auto;
                .advtm_menu_span {
                    background: transparent;
                    color: $blackcat;
                    font-size: 15px;
                    padding: 0;
                    margin: 0;
                    line-height: initial;
                    height: auto;
                    min-height: auto;
                    font-size: $font-size-md;
                    border-bottom: 2px dashed transparent;
                }
                &:hover{
                    .advtm_menu_span {
                        border-bottom: 2px dashed;
                        color: $blackcat;
                    }
                }
            }
            .adtm_sub{
                position: fixed !important;
                z-index: 0;
                width: 100%;
                top: 115px !important;
                box-shadow: none;
                padding-top: 55px !important;
                background: transparent;
                border: 0;
                .container-columnWrapTable{
                    background-color: $snow;
                    border-bottom: 1px solid #cdcdcd;
                    padding: 30px 0 60px;
                }
            }
        }
        .adtm_column{
            margin: 0;
        }
        .adtm_menu_icon {
            vertical-align: top;
            margin-right: 0;
            margin-bottom: 20px;
        }
        .adtm_column_wrap{
            padding: 0;
            max-width: 300px;
            margin: 0 auto;
            width: 100%;
            #adtm_menu .adtm_column {
                margin-left: 0;
            }
        }
        .column_wrap_title{
            a{
                font-size: $font-size-md !important;
                text-transform: uppercase !important;
                color: $blackcat;
                margin: 10px 0 10px !important;
                display: inline-block;
                font-weight: 700 !important;
                &:hover{
                    color: $blackcat !important;
                    text-decoration: underline !important;
                }
            }
        }
        .adtm_elements {
            a{
                font-size: $font-size-md !important;
                margin: 0 0 5px;
                color: $blackcat;
                &:hover{
                    color: $blackcat !important;
                    text-decoration: underline !important; 
                }
            }
        }
    }
    #adtm_menu_inner{
        border: 0;
        background: transparent;
    }
    #menu{
        justify-content: center !important;
    }
}
.header-top-fixed{
    .adtm_menu_container {
        #adtm_menu{
            .li-niveau1 {
                .adtm_sub{
                    top: 55px  !important;
                    padding-top: 55px !important;
                }
            }
        }
    }
}




// Tablet - One row
@include media-breakpoint-down(lg) {
    .show-menu-mobile{
        display: inline-block !important;
    }
    .header-nav{
        position: fixed;
        width: 100%;
        height: 100%;
        background: $snow;
        left: 0;
        right: 0;
        top: 0;
        z-index: 100;
        a{
            color:  $blackcat !important;
            span{
                color:  $blackcat !important;
            }
        }
    }

    .adtm_menu_container {
        max-width: initial;
        #adtm_menu{
            .li-niveau1 {
                border-top: 1px solid $blackcat;
                padding: 20px;
                width: 100%;
                margin: 0;
                position: relative;
                display: inline-block;
                &:last-child{
                    border-bottom: 1px solid $blackcat;
                }
                .a-niveau1{
                    margin: 0;
                }
                .advtm_menu_span{
                    font-size: 17px !important;
                    font-weight: 500 !important;
                }
                .adtm-toggle-submenu-mobile{                    
                    background-image: url(../img/svg/icon-arrowb.svg);
                    background-color: transparent;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 30px;
                    width: 50px;
                    height: 60px;
                    position: absolute;
                    right: 20px;
                    top: 0;
                    z-index: 100;
                }

                    
                &:hover{
                    .adtm_sub{
                        display: none;
                    }
                }
                
                .adtm_sub{
                    .columnWrapTable{
                        tr{
                            display: inline-grid;
                            .adtm_column_wrap{
                                max-width: inherit;
                            }
                        }
                    }
                    &.adtm_sub-active{
                        opacity: 1 !important;
                        visibility: visible !important;
                        position: initial !important;
                        margin: 0 !important;
                        padding: 0 !important;
                        width: 100%;
                        display: inline-block;
                        .container-columnWrapTable {
                            padding: 20px 0;
                            border: 0;
                            .container{
                                margin: 0;
                                padding: 0;
                                width: 100%;
                                max-width: inherit;
                                tr{
                                    width: 100%;
                                    .column_wrap_title{
                                        a{
                                            display: inline-block;
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .adtm_menu_icon {
                        vertical-align: top;
                        margin-right: 0;
                        margin-bottom: 20px;
                        display: none;
                    }

                }

                
                .adtm_column{
                    position: relative;
                }
                .adtm-toggle-submenu-column-mobile{               
                    background-image: url(../img/svg/icon-close.svg);
                    background-color: transparent;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    right: 20px;
                    top: 0;
                    z-index: 100;
                    transform: rotate(45deg);
                    &.close{
                        transform: rotate(0);
                    }
                }
                .adtm_elements {
                    display: none;
                    &.adtm_elements-active{
                        display: inline-block;
                    }
                }

            }
        }
    }
}
;@import "sass-embedded-legacy-load-done:43069";