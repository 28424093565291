.video-1-container{
    display: inline-block;
    padding: 80px 0;
    width: 100%;
    position: relative;
    height: 1005px;   
    color: $white;  
    text-align: center;
    display: flex;

    .container{
        position: relative;
        z-index: 1;
    }
    
    .ontitle{
        text-align: center;
        text-transform: uppercase;
        font-size: 14px;
        display: block;    
        color: $white;  
    }
    h3{        
        text-align: center;
        font-family: $libre-baskerville;
        color: $white;        
        line-height: 40px;
        font-size: 30px;
        text-align: center;
        max-width: 350px;
        margin: 0 auto 40px;
    }
    .content{
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        color: $white;      
        p{
            color: $white;     
        }
    }
    video {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
    }
}  
 
.video-2-container{
    display: inline-block;
    padding: 80px 0;
    width: 100%;
    position: relative;
    text-align: center;
    .ontitle{
        text-align: left;
        text-transform: uppercase;
        font-size: 14px;
        display: block;    
        color: $blackcat;  
    }
    h3{        
        text-align: left;
        font-family: $libre-baskerville;
        color: $blackcat;        
        line-height: 40px;
        font-size: 30px;
        margin: 0 0 40px;
    }
    .content{
        text-align: left;
    }
    
    .content-video{
        display: flex;
        width: 100%;
        color: $blackcat;  
        max-width: 900px;
        margin: 0 auto;
        table{
            width: 100%;
        }
        .col-left{
            width: 35%;
            position: relative;
        }
        .col-right{
            width: 55%;
        }
        .video-top{
            display: flex;
            justify-content: space-around;
            .video-top-title{
                span{
                    font-family: $libre-baskerville;
                    display: block;
                    font-size: 22px;
                }
            }
        }
        .video-bottom{
            font-family: $libre-baskerville;
            text-align: left;
            font-style: italic;
        }
        .video-left{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            .top{
                width: 100%;
                position: absolute;
                left: 0;
                top: 15%;
            }
            .bottom{
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 25%;
            }
            .text{
                border-bottom: $border-width solid  $blackcat;
                text-align: left;
                padding-right: 20px;
                font-size: 15px;
            }
        }
        .video-right{
            video{
                width: 100%;
            }
        }
    }
}

// Mobile - Two row
@include media-breakpoint-down(md) {

}

// Mobile - One row
@include media-breakpoint-down(sm) {
    .video-1-container{
        h3{
            font-size: 18px;
            line-height: 25px;
            margin: 0 auto 20px;
        }
    }
    .video-2-container {
        h3{
            font-size: 18px;
            line-height: 25px;
            margin: 0 0 20px;
        }
        .content-video {
            font-size: 11px;
            .video-top {
                .video-top-title {
                    span {
                        font-size: 11px;
                    }
                }
            }
            .video-left{
                .text{
                    font-size: 13px;
                }
            }
        }
    }
}
;@import "sass-embedded-legacy-load-done:43081";