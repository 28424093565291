@import "~@fontsource/inter/index.css"; // ??

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.woff2") format("woff2"), url("~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.woff") format("woff");
  font-display: swap;
}

.material-icons {
  display: inline-block;
  width: 1em;
  height: 1em;
  font-family: "Material Icons", Arial, sans-serif;
  font-size: 24px; /* Preferred icon size */
  font-style: normal;
  font-weight: 500;

  /* Support for IE. */
  font-feature-settings: "liga";

  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  vertical-align: middle;

  /*! /* @noflip */
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;

  /* Support for Safari and Chrome. */
  // stylelint-disable-next-line
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
}

// Heading
@font-face {
  font-family: "Libre baskerville";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Libre_Baskerville/LibreBaskerville-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Libre baskerville";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/Libre_Baskerville/LibreBaskerville-Italic.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Libre baskerville";
  font-style: bold;
  font-weight: 700;
  src: url("../fonts/Libre_Baskerville/LibreBaskerville-Bold.ttf") format("truetype");
  font-display: swap;
}

// Text
@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf") format("truetype");
  font-display: swap;
}
;@import "sass-embedded-legacy-load-done:43035";