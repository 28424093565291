#wrapper{
    display: inline-block;
    width: 100%;
    height: auto;
    padding-top: 170px;
}

#header{
    position: absolute;
    z-index: 1000;
    width: 100%;
    background-color: #fff;
}
.page-index{
    #header{
        background-color: transparent;
    }
    #wrapper{
        padding-top: 0;
    }
}

.header-top-event {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    min-height: 60px;
    text-align: center;

    background-color: var(--bs-almond);
    .custom-text-message-header{
        display: inline-block;
        width: 100%;
        position: relative;
        .swiper-wrapper {
            align-items: center;
        }
    }
    p{
        margin: 0;
        color: $blackcat;
    }
}

.header-top{
    height: 110px;
    display: flex;
    font-size: $font-size-md;    
    position: relative;

    &.header-top-fixed{
        position: fixed;
        width: 100%;
        background: $snow;
        top: 0; 
        left: 0;
        z-index: 1;
        box-shadow: 0 0 5px $blackcat;
    }

    .header-container{
        display: flex;
    }
    .header-left{
        float: left;
        width: 20%;
        display: flex;
        align-items: center;            
        .header-logo {
            display: inline-block;
            .logo{
                height: 70px;
                &.logo-dark{
                    display: none;
                }
            }
        }
    }
    .header-middle{
        float: left;
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        .header-nav{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;     
        }
    }
    .header-right{
        float: left;
        width: 20%;
        display: flex;
        justify-content: right;
        align-items: center;
        gap: 8px;
        .user-info{
            display: flex;
            gap: 0 10px;
            .account{
                i{
                    font-size: 20px !important;
                }
            }
        }
    }
    
    .user-info{
        &>a{
            text-transform: uppercase;
        }
    }
    .blockcart {
        .header {
            &>a{
                text-transform: uppercase;
            }
        }
    }   

    #blockcart-wrapper{
        .blockcart {
            .header{
                display: inline-block;
                width: 100%;
            }
            .body{
                display: none;
            }
        }
    }

    .wishlist{
        a{
            display: flex;
            &::before{
                content: "";
                background-image: url(../img/svg/icon-hearth.svg);
                background-color: transparent;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                width: 17px;
                height: 17px;
                display: inline-block;
            }
        }
    }
    .search{
        a{
            display: flex;
            &::before{
                content: "";
                background-image: url(../img/svg/icon-search.svg);
                background-color: transparent;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                width: 17px;
                height: 17px;
                display: inline-block;
            }
        }
    }


    &.theme-clear{
        
        .wishlist{
            a{
                &::before{
                    background-image: url(../img/svg/icon-hearth-w.svg);
                }
            }
        }
        .search{
            a{
                &::before{
                    background-image: url(../img/svg/icon-search-w.svg);
                }
            }
        }
    }
}


.header-light{
    .header-top{
        .header-logo{
            .logo{
                &.logo-light{
                    display: block ;
                }
                &.logo-dark{
                    display: none ;
                }
            }
        }
        
        .wishlist{
            a{
                &::before{
                    background-image: url(../img/svg/icon-hearth.svg);
                }
            }
        }
        .search{
            a{
                &::before{
                    background-image: url(../img/svg/icon-search.svg);
                }
            }
        }        
        .adtm_menu_container {
            #adtm_menu{
                .li-niveau1 {
                    .a-niveau1{
                        .advtm_menu_span{
                            color: $blackcat;
                        }
                    }
                }
            }
        }
        .user-info{
            &>a{
                color: $blackcat;
            }
        }
        .blockcart {
            .header {
                &>a{
                    color: $blackcat;
                }
            }
        }   
    }
}

.header-dark{
    .header-top{
        .header-logo{
            .logo{
                &.logo-light{
                    display: none ;
                }
                &.logo-dark{
                    display: block ;
                }
            }
        }
        
        .wishlist{
            a{
                &::before{
                    background-image: url(../img/svg/icon-hearth-w.svg);
                }
            }
        }
        .search{
            a{
                &::before{
                    background-image: url(../img/svg/icon-search-w.svg);
                }
            }
        }        
        .adtm_menu_container {
            #adtm_menu{
                .li-niveau1 {
                    .a-niveau1{
                        .advtm_menu_span{
                            color: $white;
                        }
                    }
                }
            }
        }
        .user-info{
            &>a{
                color: $white;
            }
        }
        .blockcart {
            .header {
                &>a{
                    color: $white;
                }
            }
        }
    }
}

.header-light,
.header-dark{
    .header-top-fixed{
        .header-logo{
            .logo{
                &.logo-light{
                    display: block ;
                }
                &.logo-dark{
                    display: none ;
                }
            }
        }
        
        .wishlist{
            a{
                &::before{
                    background-image: url(../img/svg/icon-hearth.svg);
                }
            }
        }
        .search{
            a{
                &::before{
                    background-image: url(../img/svg/icon-search.svg);
                }
            }
        }
        .adtm_menu_container {
            #adtm_menu{
                .li-niveau1 {
                    .a-niveau1{
                        .advtm_menu_span{
                            color: $blackcat;
                        }
                    }
                }
            }
        }
        .user-info{
            &>a{
                color: $blackcat;
            }
        }
        .blockcart {
            .header {
                &>a{
                    color: $blackcat;
                }
            }
        }   
    }
}

.header-nav-mobile{
    display: none;
}

.menu-mobile{
    display: none;
}

.header-nav-mobile-before,
.header-nav-mobile-after{
    display: none;
}
.header-nav-mobile-before{
    flex-wrap: wrap;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    img{
        width: 150px;
    }
    .header-nav-mobile-close{           
        background-image: url(../img/svg/icon-close.svg);
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 30px;
        position: absolute;
        right: 20px;
        top: 0;
        z-index: 100;
        width: 25px;
        height: 25px;
        position: relative;
        cursor: pointer;
    }
}
.header-nav-mobile-after{
    width: 100%;
    padding: 50px 20px 20px;
    flex-wrap: wrap;
    a{
        margin: 10px 0;
        padding: 10px 0;
        text-transform: uppercase;
        width: 100%;
        &.logout{
            color: red !important;
        }
    }
}

.container-search{
    background-color: $snow;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: none;
    z-index: 10000;

    &.show-container-search{
        display: flex;
    }
    
    .container-search-close{
        background-image: url(../img/svg/icon-close.svg);
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 30px;
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 100;
        width: 25px;
        height: 25px;
        cursor: pointer;
    }

    .search-widgets{
        min-width: 380px;
    }
}
.ui-autocomplete.searchbar-autocomplete{
    z-index: 10000;
}

// Tablet - One row
@include media-breakpoint-down(lg) {

    .header-top{
        .header-left{
            width: 40%;
        }
        .header-middle{
            display: none;
        }
        .header-right{
            width: 60%;
            .user-info{
                display: none;
            }
        }
    }

    .menu-mobile{
        display: flex;
        position: fixed;
        left: 0;
        bottom: 0;
        justify-content: center;
        align-items: center;
        width: 100%;
        a{
            background-color: $white;
            border-radius: 50px;
            padding: 10px 20px;
            margin-bottom: 45px;
            box-shadow: 0px 0px 5px $blackcat;
            color: $blackcat;
            cursor: pointer;
            font-size: $font-size-md;
        }
    }

    .header-nav{
        overflow-y: scroll;
        overflow-x: hidden;
        flex-wrap: wrap;
        align-items: start;
        justify-content: center;
        padding-top: 120px;
    }
    .header-nav-mobile-before,
    .header-nav-mobile-after{
        display: flex;
        width: 100%;
    }

    .container-search {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        align-items: baseline;
        z-index: 10000;
    }
}


// Mobile - Two row
@include media-breakpoint-down(md) {
    .header-top {
        font-size: $font-size-md;
        .header-left {
            .header-logo {
                .logo {
                    height: 40px;
                }
            }
        }
        .header-right{
            gap: 10px;
        }

        .user-info{
            display: none;
        }
    }
    
.container-search{
    .search-widgets{
        min-width: auto;
        margin: 35px 0 0;
    }
}
}

;@import "sass-embedded-legacy-load-done:43041";