.categoriesproducts-container{
    display: inline-block;
    padding: 80px 0;
    width: 100%;
    position: relative;
    gap: 25px 5%;

    .ontitle{
        text-align: left;
        text-transform: uppercase;
        font-size: 14px;
        display: block;
        color: $blackcat;        
    }
    h3.title{        
        text-align: left;
        font-family: $libre-baskerville;
        color: $blackcat;        
        line-height: 40px;
        font-size: 30px;
        margin: 0 0 40px;
    }
    .description{
        text-align: left;
        color: $blackcat;    
        max-width: 400px;    
    }
    
    

    .categories-products{
        display: flex;
        width: 100%;
        margin-top: 50px;
        justify-content: space-between;
        overflow: hidden;
    
        .categories{
            width: 50%;
            position: relative;
            .bloc-categories-filter{
                display: inline-block;
                width: 100%;
            }
            .list{
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                justify-content: left;
                gap: 20px 5%;
                .category{
                    width: 135px;
                    width: 20%;
                    height: 113px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    .svg-icone{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }
                    span{
                        position: relative;
                        z-index: 1;
                        font-size: 13px;
                        padding: 0 30px;
                        text-align: center;
                    }
                }
            }
        }
        .products{
            display: inline-block;
            width: 45%;
            .categoriesproducts-products-title{
                display: none;
            }
            .list{
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 2%;
                display: none;
                &.active{
                    display: flex;
                }
                .swiper-wrapper{
                    flex-wrap: wrap;
                    height: auto;
                    justify-content: space-between;
                }
                .swiper-slide{
                    width: 48%;
                }
                article{
                    width: 100%;
                    .card-body {
                        padding: 0;
                        margin: 15px 0 0;
                        text-align: center;
                        text-transform: uppercase;
                        font-size: 14px;
                        p{                                                    
                            margin: 5px;
                        }
                    }
                }
            }
        }
    }


    .swiper-categoriesproducts{
        overflow: inherit;
    }

    
    .swiper-button-prev{
        background: transparent;      
        width: 30px;
        height: 50px;
        border-radius: 0;
        border: 0;
        top: 50%;
        left: 30px;
        display: none;
        &::after{
            background-image: url(../img/svg/icon-arrowl.svg);
            content: " ";
            width: 30px;
            height: 10px;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
    .swiper-button-next{
        background: transparent;      
        width: 30px;
        height: 50px;
        border-radius: 0;
        border: 0;
        top: 50%;
        right: 30px;
        display: none;
        &::after{
            background-image: url(../img/svg/icon-arrowr.svg);
            content: " ";
            width: 30px;
            height: 10px;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
    .swiper-button-disabled{
        display: none;
    }


}

.category-23 span{
    font-size: 90%!important;
    top: -4px;
}

.category-33 span{
    font-size: 85%!important;
    padding: 50px 10px 0!important;
}

.category-34 span{
    font-size: 80%!important;
}

// Tablet
@include media-breakpoint-down(lg) {
    .categoriesproducts-container{
        display: inline-block;
        padding: 80px 0;
        width: 100%;
        position: relative;
    
        .ontitle{
            text-align: left;
            text-transform: uppercase;
            font-size: 14px;
            display: block;
            color: $blackcat;        
        }
        h3.title{        
            text-align: left;
            font-family: $libre-baskerville;
            color: $blackcat;        
            line-height: 40px;
            font-size: 30px;
        }
        .description{
            text-align: left;
            color: $blackcat;    
            max-width: 100%;    
        }
        
    
        .categories-products{
            flex-wrap: wrap;
            .categories{
                width: 100%;
                .list{
                    justify-content: space-around;
                }
            }
            .products{
                position: fixed;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: #fff;
                display: none;
                justify-content: center;
                padding: 30px;
                z-index: 10000;
                &.active{
                    display: flex;
                }
                .categoriesproducts-products-close-js{
                    background-image: url(../img/svg/icon-close.svg);
                    position: absolute;
                    right: 30px;
                    top: 30px;
                    width: 20px;
                    height: 20px;
                    background-repeat: no-repeat;
                    cursor: pointer;
                }
                .categoriesproducts-products-title{
                    text-align: left;
                    font-family: $libre-baskerville;
                    color: $blackcat;
                    line-height: 40px;
                    font-size: 30px;
                    display: inline-block;
                    width: 100%;
                    margin-bottom: 50px;
                }
                .list{
                    .swiper-wrapper{
                        flex-wrap: nowrap;
                        height: 100%;
                        justify-content: space-between;
                    }
                    .swiper-slide{
                        width: 100%;
                        align-items: center;
                        display: flex;
                    }
                }
                
                .swiper-button-next,
                .swiper-button-prev{
                    display: flex;
                }
                .swiper-button-disabled{
                    display: none;
                }
            }
        }
    }
}
    
// Mobile
@include media-breakpoint-down(md) {

}


// Mobile
@include media-breakpoint-down(sm) {


    .categoriesproducts-container{
            
        h3.title{              
            font-size: 18px;
            line-height: 25px;
            margin: 0 0 20px;
        }

        .categories-products {
            .categories {
                .list {
                    .category {
                        width: 85px;
                        height: 85px;
                    }
                }
            }
            
            .products{
                .categoriesproducts-products-title{
                    font-size: 20px;
                    line-height: 25px;
                }
                .list{
                    .swiper-wrapper{
                        /*justify-content: space-around;*/
                    }
                }
            }
        }
    }
}


;@import "sass-embedded-legacy-load-done:43089";