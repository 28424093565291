.philosophy-details{
    .title{
        text-align: left !important;
        margin: 0 0 20px !important;
    }
    .swiper{
        text-align: center;
        .imageslist3-container .list.actifs-list {
            margin: 30px auto;
            max-width: 1200px;
        }
    }
    .infos{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 30px;
        .actifs-link{

        }
        .text{

        }
    }
}

.raison-container{
    display: inline-block;
    padding: 80px 0;
    width: 100%;
    position: relative;
    height: 1005px;   
    color: $white;  
    text-align: center;
    display: flex;

    .container{
        position: relative;
        z-index: 1;
    }
    
    .ontitle{
        text-align: center;
        text-transform: uppercase;
        font-size: 14px;
        display: block;    
        color: $white;  
    }
    h3{        
        text-align: center;
        font-family: $libre-baskerville;
        color: $white;        
        line-height: 40px;
        font-size: 30px;
        text-align: center;
        max-width: 350px;
        margin: 0 auto 40px;
    }
    .content{
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        color: $white;      
        p{
            color: $white;     
        }
        a{            
            color: $white;     
            font-style: italic;
            text-decoration: underline;
        }
    }
    img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
    }
}  

.valeur-container{
    display: inline-block;
    padding: 80px 0;
    width: 100%;
    position: relative;
    height: auto;   
    text-align: center;
    display: flex;
    h3{        
        text-align: center;
        font-family: $libre-baskerville;
        line-height: 40px;
        font-size: 30px;
        text-align: center;
        margin: 0 auto 40px;
    }
    .etoile{
        background-image: url(../img/svg/etoile.svg);
        background-repeat: no-repeat;
        width: 560px;
        height: 511px;
        position: relative;
        background-size: contain;
        padding: 50px;
        margin: 200px auto;
        .element{
            background-image: url(../img/svg/echange.svg);
            background-position: center center;
            background-repeat: no-repeat;
            font-family: "Libre baskerville", -apple-system, Arial, Helvetica, sans-serif;
            width: 160px;
            height: 160px;
            background-color: #f9f9f9;
            background-size: 140px;
            border-radius: 180px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 38px;
            position: absolute;
            font-style: italic;
            a{
                cursor: pointer;
            }
        }
        .element-1{
            background-image: url(../img/svg/generosite.svg);
            top: -150px;
            left: 200px;
        }
        .element-2{
            background-image: url(../img/svg/naturalite.svg);
            right: -180px;
            top: -30px;
        }
        .element-3{
            background-image: url(../img/svg/liberte.svg);
            right: -180px;
            top: 390px;
        }
        .element-4{
            background-image: url(../img/svg/universalite.svg);
            bottom: -150px;
            left: 200px;
        }
        .element-5{
            background-image: url(../img/svg/perenite.svg);
            left: -180px;
            top: 390px;
        }
        .element-6{
            background-image: url(../img/svg/echange.svg);
            left: -180px;
            top: -30px;
        }
        .element-7{
            background-image: url(../img/svg/honnetete.svg);
            top: 165px;
            left: 200px;
        }
    }
}

.star-element-popup{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #f9f9f9;
    padding: 140px 140px;
    z-index: 1000;
    display: none;
    &.actif{
        display: inline-block;
    }
    &::before{
        content: " ";
        border: 1px dashed var(--bs-almond);
        position: absolute;
        left: 60px;
        top: 0;
        height: 100%;
    }
    &::after{
        content: " ";
        border: 1px dashed var(--bs-almond);
        position: absolute;
        right: 60px;
        top: 0;
        height: 100%;
    }
    .star-element-popup-2{
        &::before{
            content: " ";
            border: 1px dashed var(--bs-almond);
            position: absolute;
            top: 60px;
            left: 0;
            width: 100%;
        }
        &::after{
            content: " ";
            border: 1px dashed var(--bs-almond);
            position: absolute;
            bottom: 60px;
            left: 0;
            width: 100%;
        }
        .star-element-popup-close{   
            color: $blackcat;
            position: relative;
            font-style: italic;
            display: block;
            margin: 0;
            padding: 0;
            font-size: 15px;
            text-decoration: underline;
            &::before{
                content: " ";
                background-image: url(../img/svg/icon-arrowb.svg);
                background-color: transparent;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                width: 24px;
                height: 15px;
                border: 0;
                float: left;
                margin-right: 10px;
                margin-top: 5px;
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
            }
        }
        .detail{
            max-width: 500px;
            margin: 20px auto;
        }
        .star-element-title{
            background-image: url(../img/svg/echange.svg);
            background-position: center center;
            background-repeat: no-repeat;
            font-family: "Libre baskerville", -apple-system, Arial, Helvetica, sans-serif;
            background-color: #f9f9f9;
            background-size: 140px;
            border-radius: 180px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 38px;
            font-style: italic;
            height: 150px;
            margin: 0 0 30px;
        }
        .star-element-description{
            font-weight: bold;
            font-family: "Libre baskerville", -apple-system, Arial, Helvetica, sans-serif;
            font-size: 18px;
        }
    }
}

// Mobile
@include media-breakpoint-down(md) {
    .philosophy-details{
        .title{
            text-align: center !important;
        }
        .infos{
            justify-content: center;
            .actifs-link{
                order: 1;
            }
            .text{
                order: 0;
            }
        }
    }
    
    .raison-container{
        h3{
            font-size: 18px;
            line-height: 25px;
            margin: 0 auto 20px;
        }
    }

    
    .valeur-container{
        h3{        
            font-size: 18px;
            line-height: 25px;
            margin: 0 auto 20px;
        }
        .etoile{
            width: 340px;
            height: 340px;
            padding: 20px;
            margin: 80px auto;
            .element{
                width: 95px;
                height: 95px;
                background-size: 95px;
                font-size: 20px;
            }
            .element-1{
                top: -85px;
                left: 125px;
            }
            .element-2{
                background-image: url(../img/svg/naturalite.svg);
                right: -10px;
                top: 10px;
            }
            .element-3{
                background-image: url(../img/svg/liberte.svg);
                right: -10px;
                top: 215px;
            }
            .element-4{
                bottom: -60px;
                left: 120px;
            }
            .element-5{
                background-image: url(../img/svg/perenite.svg);
                left: -10px;
                top: 215px;
            }
            .element-6{
                background-image: url(../img/svg/echange.svg);
                left: -10px;
                top: 10px;
            }
            .element-7{
                background-image: url(../img/svg/honnetete.svg);
                top: 105px;
                left: 120px;
            }
        }
    }
    .star-element-popup {
        padding: 60px 40px;
        &::before{
            left: 15px;
        }
        &::after{
            right: 15px;
        }
        .star-element-popup-2{
            &::before{
                top: 15px;
            }
            &::after{
                bottom: 15px;
            }
            .star-element-description {
                font-size: 15px;
            }
        }
    }
}
;@import "sass-embedded-legacy-load-done:43075";