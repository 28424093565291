.specialproducts-container{
    display: inline-block;
    padding: 80px 0;
    width: 100%;
    overflow: clip;
    position: relative;

    .ontitle{
        text-align: left;
        text-transform: uppercase;
        font-size: 14px;
        display: block;
        color: $blackcat;        
    }
    h3.title{        
        text-align: left;
        font-family: $libre-baskerville;
        color: $blackcat;        
        line-height: 40px;
        font-size: 30px;
        margin: 0 0 40px;
    }
    .description{
        text-align: left;
        color: $blackcat;        
    }
    
    .swiper-specialproducts{
        overflow: inherit;
    }
    .specialproducts-active-scroll{
        .swiper-wrapper{
            .swiper-slide{
                &:first-child{
                    opacity: 0;
                }
            }
        }
    }

    
    .swiper-button-prev{
        left: 50px;
        right: 50px;
        top: 40%;
        background: $white;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        border:  $border-width solid $blackcat;
        &::after{
            background-image: url(../img/svg/icon-arrowl.svg);
            content: " ";
            width: 30px;
            height: 10px;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
    .swiper-button-next{
        right: 50px;
        right: 50px;
        top: 40%;
        background: $white;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        border:  $border-width solid $blackcat;
        &::after{
            background-image: url(../img/svg/icon-arrowr.svg);
            content: " ";
            width: 30px;
            height: 10px;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
    .swiper-button-disabled{
        display: none;
    }

    .swiper-scrollbar{
        display: inline-block;
        width: 100%;
        height:  1px;
        background-color: $blackcat;
        margin: 50px 0;
        position: relative;
        .swiper-scrollbar-drag{
            position: relative;
            &::before{
                content: " ";
                width: 60px;
                height: 21px;
                background: $snow;
                border:  $border-width solid $blackcat;
                position: absolute;
                top: -10px;
                right: 50%;
                left: 50%;
                display: block;
            }
        }
    }

    .all-products-Link{
        margin: 0;
    }
}


// Tablet - One row
@include media-breakpoint-down(lg) {
    
}


// Mobile - Two row
@include media-breakpoint-down(md) {
    .specialproducts-container {
        .description {
            margin-bottom: 50px;
        }
        .all-products-Link{
            margin-top: 50px;
        }
        
        .swiper-wrapper{
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 20px 4% ;

            .swiper-slide{
                width: 48%;
                &:first-child{
                    width: 100%;
                }
            }
        }    
        .swiper-button-prev,
        .swiper-button-next,
        .swiper-scrollbar{
            display: none;
        }
    }
}


// Mobile - One row
@include media-breakpoint-down(sm) {
    .specialproducts-container {
            
        h3.title{              
            font-size: 18px;
            line-height: 25px;
            margin: 0 0 20px;
        }
        
        .swiper-wrapper{
            gap: 20px 0;

            .swiper-slide{
                width: 100%;
            }
        }    
    }    
}
;@import "sass-embedded-legacy-load-done:43087";