
.product-reviews{
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-size: 13px;
    text-transform: uppercase;
    .stars{
      display: inline-block;
      span{                            
          background-image: url(../img/svg/icon-star-filled.svg);
          width: 11px;
          height: 11px;
          background-size: contain;
          background-repeat: no-repeat;
          display: inline-block;
          margin: 0;
          padding: 0;
      }
    }
}

.reviewslist-container{
    display: inline-block;
    padding: 80px 0;
    width: 100%;
    position: relative;
    .title{
        text-align: center;
        font-family: $libre-baskerville;
        color: $blackcat;
        line-height: 40px;
        font-size: 30px;
        text-align: center;
        margin: 0;
    }
    .description{
        text-align: center;
        font-size: 16px;
        display: block;
        max-width: 800px;
        margin: 20px auto;
    }
    .product-reviews{
        margin-top: 0;
    }
    .reviewslist{
        width: 100%;
        max-width: 700px;
        margin: 40px auto;
        display: block;
        .review{
            border-top: 1px solid $blackcat;
            display: inline-block;
            width: 100%;
            padding: 40px 0;    
            list-style: none;
            &::before{
                display: none;
            }
            &.hidden{
                display: none;
            }
            &:last-child{
                border-bottom: 1px solid $blackcat;
            }    
            .review-stars{
                display: inline-block;
                span{                            
                    background-image: url(../img/svg/icon-star-filled.svg);
                    width: 11px;
                    height: 11px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    display: inline-block;
                    margin: 0;
                    padding: 0;
                }
            }
            .review-title{
                display: inline-block;
                width: 100%;
                margin: 15px 0 0 ;
                font-weight: bold;
            }
            .review-comment{
                display: inline-block;
                width: 100%;
                margin: 0 0 15px;
            }
            .review-name{
                display: inline-block;
                width: 100%;
            }
        }
    }
    .pagination-container{
        display: flex;
        justify-content: center;
        .pagination-button{
            border: 0;
            background: transparent;
            margin: 0 10px;
        }
        .pagination-number{
            border: 0;
            background: transparent;
            margin: 0 10px;
            &.active{
                text-decoration: underline;
            }
        }
    }
}
;@import "sass-embedded-legacy-load-done:43053";