
.default-page{
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: 0;
    .default-pagetitle{
        background: $white;
        max-width: 500px;
        margin: 35px auto 80px;
        padding: 50px;
        position: relative;
        width: 100%;
        &::before{
            content: " ";
            position: absolute;
            left: 25px;
            top: 25px;
            right: 25px;
            bottom: 25px;
            border: 2px dashed $lightapricot;
            z-index: 0;
        }
        .page-ontitle-section{
            text-transform: uppercase;
            text-align: center;
            font-size: 15px;
            display: block;
            margin: 0 auto 5px;
        }
        .page-title-section{
            margin: 0 !important;
            position: relative;
            z-index: 1;
            color:  $blackcat;
            text-align: center;
            font-size: 25px;
        }
        .description{
            margin: 20px 0 0 !important;
            position: relative;
            z-index: 1;
            color:  $blackcat;
            font-size: $font-size-md;
            text-align: center;
        }
    }
}


// Mobile
@include media-breakpoint-down(md) {
    .default-page{
        .default-pagetitle {
            margin: 20px auto 20px;
        }
    }
}
;@import "sass-embedded-legacy-load-done:43107";