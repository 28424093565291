.globalreviews-container{
    display: inline-block;
    padding: 80px 0;
    width: 100%;

    h3{        
        text-align: center;
        font-family: $libre-baskerville;
        color: $blackcat;        
        line-height: 40px;
        font-size: 30px;
        text-align: center;
        margin: 0 0 20px;
    }
    p.total{
        text-align: center;
        margin-bottom: 20px;
        .stars{
            display: inline-block;
            span{                            
                background-image: url(../img/svg/icon-star-filled.svg);
                width: 13px;
                height: 13px;
                background-size: contain;
                background-repeat: no-repeat;
                display: inline-block;
                margin: 0;
                padding: 0;
            }
        }
    }
    a{
        text-decoration: underline;
    }
}

// Mobile - Two row
@include media-breakpoint-down(md) {

}

// Mobile - One row
@include media-breakpoint-down(sm) {
    .globalreviews-container{    
        h3{            
            font-size: 18px;
            line-height: 25px;
            margin: 0 0 20px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:43085";