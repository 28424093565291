//
// Variables Prestashop - Classic Theme
//

// General colors
$default-icon-color: var(--bs-gray-700);

// Header
$header-bg: #fff !default;
$header-top-bg: $header-bg !default;
$header-top-border-bottom: 1px solid var(--bs-gray-200);
$header-bottom-border-bottom: $header-top-border-bottom !default;
$header-color: var(--bs-gray-800);
$header-top-color: $header-color;

$header-block-spacing-y: 0.75rem !default;
$header-block-spacing-x: 0.25rem !default;
$header-block-spacing-lg-y: .375rem !default;
$header-block-spacing-lg-x: .75rem !default;


// Footer
$footer-background-color: #16181c !default;
$footer-heading-color: var(--bs-white);
$footer-text-color: var(--bs-gray-300);

// Emailsubscriptions
$email-input-color: var(--bs-gray-300);
$email-input-border-color: var(--bs-gray-300);

// Carousel
$carousel-control-height: 4rem !default;
$slider-control-bg: var(--bs-white);
$slider-control-radius: 50% !default;

$slider-title-weight: 800 !default;
$slider-title-size: 3rem !default;
$slider-subtitle-weight: 700 !default;
$slider-subtitle-size: 1.25rem !default;
$slider-text-color: var(--bs-gray-800);
$slider-text-spacing: 1rem !default;

$slider-description-size: 1rem !default;

$slider-img-height: 100% !default;

// Note for Prestashop team : not the good place for this rule
.carousel {
  --slider-img-height: #{$slider-img-height};
  --slider-title-weight: #{$slider-title-weight};
  --slider-subtitle-weight: #{$slider-subtitle-weight};
  --slider-text-color: #{$slider-text-color};
  --slider-control-bg: #{$slider-control-bg};
  --slider-control-height: #{$carousel-control-height};
  --slider-control-radius: #{$slider-control-radius};
}

// Button with icon
$default-button-icon-size: 1.5rem !default;
$default-button-icon-gap: 0.25rem !default;

$small-button-icon-size: 1.25rem !default;
$small-button-icon-gap: 0.125rem !default;

$large-button-icon-size: 1.875rem !default;
$large-button-icon-gap: 0.375rem !default;

;@import "sass-embedded-legacy-load-done:42869";