.reassurance-container{
    display: inline-block;
    width: 100%;
    ul{        
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: $spacer;
        gap: 2%;
        
        li{
            width: 15%;
            text-align: center;
            max-width: 200px;
            .img-c{
                height: 100px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            
                img{
                    max-width: 50px;
                    max-height: 50px;
                }
            }
            .caption-top{
                display: block;
                text-transform: uppercase;
                font-size: 14px;
                margin-bottom: 5px;
            }
            .caption-bottom{
                display: block;
                font-size: 14px;
                font-style: italic;
                font-family: $libre-baskerville;
            }
        }
    }
}


// Tablet
@include media-breakpoint-down(md) {    
    .reassurance-container{
        ul{                
            li{
                width: 30%;
            }
        }
    }
}

// Mobile
@include media-breakpoint-down(sm) {   
    .reassurance-container{ 
        ul{                
            li{
                width: 45%;
            }
        }
    }
}
;@import "sass-embedded-legacy-load-done:43079";