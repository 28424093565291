#product{
  background-color: $white;
  .product__left{
    position: relative;
  }
  .product-category {
    background-color: $almond;
    color: $blackcat;
    align-items: center;
    border-radius: 65px;
    color: #412c3d;
    display: flex;
    font-size: 12px;
    height: 65px;
    justify-content: center;
    position: absolute;
    text-align: center;
    top: 25px;
    width: 65px;
    z-index: 100;
  }
}

.product-miniature{
  .card{
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0 0 20px;
    .card-img, .card-img-top, .card-img-bottom {
        width: 100%;
        height: auto;
    }
  }
  .product-category{
    background-color: $almond;
    color: $blackcat;
    position: absolute;
    left: 5px;
    top: 5px;
    height: 65px;
    width: 65px;
    font-size: 12px;
    display: flex;
    text-align: center;
    align-items: center;
    border-radius: 65px;
    z-index: 100;
    justify-content: center;
  }
  .product-infos{
    margin: 0;
    padding: 0;
    font-size: 13px;
    display: flex;
    text-align: center;
    width: 100%;
    margin-bottom: 5px;
    gap: 0 5px;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 40px;
  }

  .product-flags{
    display: none;
  }
  .wishlist-button-add{
    display: none;
  }

  .product-miniature__image-container{
    position: relative;
    overflow: hidden;
    .preview-product-hover{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: none;
      -o-object-fit: cover;
      object-fit: cover;
    }
    &:hover{
      .preview-product-hover{
        display: inline-block;
      }
    }
  }

  .card-body{
    padding: 0;
    margin: 15px 0 0;
    .product-miniature__infos__top{
      float: left;
      width: 70%;
    }
    .product-miniature__infos__bottom{
      float: right;
      width: 20%;
    }
    .product-miniature__title, .product-miniature__price{
      font-size: 14px;
      text-transform: uppercase;
      color: $blackcat;     
      font-weight: bold;   
      margin: 0;
    }
    form{
      padding: 0;
      width: 100%;
    }
    a.btn,button.btn{
      float: left;
      width: 100%;
      margin: 10px 0 0 !important;
      padding: 10px;
      background-color: transparent;
      border: 1.5px  solid $blackcat;
      color: $blackcat;              
      text-transform: uppercase;
      font-size: $font-size-md;
      background-color: $snow;
      &:hover{
        color: $white;  
        background-color: $blackcat;
      }
    }
  }
}



.wishlist-modal{
  .close{
    border: 0;
    background: transparent;
  }
  .modal-header {
    h5{
      text-transform: uppercase;
      font-weight: normal !important;
      font-size: 18px !important;
    }
  }
}


.product{
  .swiper-slide{
    margin-bottom: 80px;
  } 
  .product__left{
    padding: 0 120px;
    .testimonials-container{
      margin: 80px 0;
    }
    .video-product-left{
      width: 100%;
      height: auto;
    }
  }
  .product__right{
  
  }
  h1{
    text-transform: uppercase;
    margin: 0 0 20px;
  }
  .product-intro{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 20px;
    .left{
      text-align: left;
    }
    .right{
      text-align: right;
      .yuka{
        font-weight: bold;
      }
    }
  }
  .product__description-full{
    background-color: $snow;
    padding: 20px 20px 15px;
  }
  .product__prices {
    display: inline-block;
    margin: 20px 0;
    width: 100%;
    text-align: left;
    .prices__wrapper{
      float: left;
      font-size: $font-size-lg;
      font-weight: bold;
      margin-bottom: 0 !important;
      display: inline-block !important;
    }
    .product__pack-price,
    .product__tax-label{
      float: left;
      font-size: $font-size-md;
      font-weight: normal;
      margin-bottom: 0 !important;
      display: inline-block !important;
      margin: 0px 0px 0px 10px;
      line-height: 27px;
    }
  }
  
  .product-pack{
    width: 100%;
    display: inline-block;
    .h6{
      margin: 0 0 10px;
    }
  }
  .product-pack__item{
    width: 100%;
    display: inline-block;
    padding: 10px;
    border: 1px solid $snow;
    margin-bottom: 10px;
    border-radius: 0;
  }
  .product__actions {
    position: relative;
    display: inline-block;
    margin: 0 0 20px;
    width: 100%;

    .wishlist-button-product{
      position: absolute;
      top: -60px;
      right: 0;
      margin: 0;
      padding: 0;
      box-shadow: none;
    }
    .product__variants {
      display: inline-block;
      width: 100%;
      .variant{
        display: flex;
        justify-content: center;
        align-items: center;
        label{
          width: 50%;
          font-size: $font-size-md;
          font-weight: normal;
          margin: 0;
        }
        select{
          width: 50%;
          border: 1px solid $blackcat;
        }
      }
    }
    #add-to-cart-or-refresh{
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }
    .product__discounts,
    .product__add-to-cart {
      display: inline-block;
      width: 100%;
    }
    .product__availability {
      margin: 0;
      color: #FF9800;
      .material-icons{
        margin-right: 15px !important;
      }
    }
    .product-actions__quantity{
      display: none;
    }
    .product-actions__button,
    .product__additional-info{
      float: left;
      width: 100%;
      margin-bottom: 20px;
      .add-to-cart{
        display: inline-block;
        width: 100%;
        margin: 10px 0 0 !important;
        padding: 10px;
        background-color: transparent;
        border: 1.5px solid $blackcat;
        color: $blackcat;
        text-transform: uppercase;
        font-size: $font-size-md;
        background-color: $snow;
        &:hover{
          background-color: $blackcat;
          color: $snow;
        }
      }
    }
    .product-actions__button{
      margin-bottom: 0;
    }
    .product__additional-info{
      .add-to-cart{
        text-transform: none;
        background-color: #ddd8dc;
      }
    }
  }
  .product-reviews{
    margin-top: 0;
  }
  .product-extra{
    margin: 20px 0;
    display: inline-block;
    width: 100%;
    .extra-title{
      text-transform: uppercase;
      margin: 0 0 20px;
      display: inline-block;
      width: 100%;
      font-weight: 500;
    }
    .extra-details{
      display: inline-block;
      width: 100%;
      &.bienfaits{
        display: flex;
        flex-wrap: wrap;
        gap: 10px 20px;
        align-content: start;
        .bienfait{
          background-color: $almond;
          color: $blackcat;
          padding: 10px 15px;
          border-radius: 5px;
        }
      }
      &.manual-details{
        ul{
          display: flex;
          flex-wrap: wrap;
          gap: 0 50px;
          margin-top: 20px;
          li{
            line-height: 23px;
            width: 45%;
            &::before{
              background-image: url(../img/svg/icon-checked.svg);
              top: 5px;
              background-size: contain;
              width: 15px;
            }
          }
        }
      }
    }
    
    &.product-extra-sciences,
    &.product-extra-sens{
      border: 2px dashed;
      padding: 40px;
      .extra-title,
      .extra-details{
        font-family: $libre-baskerville;
        color: $blackcat;
        text-align: center;
        font-style: italic;
      }
    }
    &.product-extra-sciences{
      margin-bottom: 0;
    }
    &.product-extra-sens{
      border-top: 0;
      margin-top: 0;
    }
    &.product-extra-sens-top{
      border: 2px dashed;
      margin: 20px 0;
    }
    
    &.product-extra-sensation{
      .extra-details{
        font-style: italic;
      }
    }
  }
  .product-extra-categories{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: left;
    gap: 20px 5%;
    margin: 20px 0;
    .category{
      width: 100px;
      height: 100px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      svg{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
      span{
        position: relative;
        z-index: 1;
        font-size: 11px;
        padding: 0 30px;
        text-align: center;
      }
    }
  }
}

.product__description-short{
	margin-bottom: 20px;
}

.products-container{
  display: inline-block;
  padding: 80px 0;
  width: 100%;
  position: relative;
  border-top: 1px solid $blackcat;
  h2{
    text-align: center;
    font-family: $headings-font-family;
    color: #412c3d;
    line-height: 40px;
    font-size: 30px;
    text-align: center;
  }
}


.all-products-Link{
  color: $blackcat;
  position: relative;
  font-style: italic;
  display: inline-block;
  margin: 50px 0 0;;
  padding: 0;
  font-size: 15px;
  text-decoration: underline;
  &::before {
      content: " ";
      background-image: url(../img/svg/icon-arrowb.svg);
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 24px;
      height: 15px;
      border: 0;
      float: left;
      margin-right: 10px;
      margin-top: 5px;
  }
}


// Tablet - One row
@include media-breakpoint-down(lg) {  
  .product{    
    .product__left{  
      .testimonials-container,
      .video-product-left{
        /*display: none;*/
      }
    }
    .product-intro{
      margin-top: 20px;
    }
    
    .product-extra{
      .extra-details{
        &.manual-details{
          ul{
            li{
              width: 100%;
            }
          }
        }
      }
    }
  }
}


// Mobile - Two row
@include media-breakpoint-down(md) {
  .product{   
    .swiper-slide{
      margin-bottom: 0;
    } 
    .product__left{  
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
    }
  }
}

// Mobile - Two row
@include media-breakpoint-down(sm) {
  
}

;@import "sass-embedded-legacy-load-done:43055";