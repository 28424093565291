// Body
body, html {
	min-height: 100%;
}

body {
	font-family: $font-family-base;
	color: var(--bs-body-color);
	color-scheme: light;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: $font-size-md;
}

// Header
strong, .strong {
	font-weight: 500;
	////letter-spacing: $letter-spacing-text-strong;
}

small, .small {
	font-weight: 400;
	////letter-spacing: $letter-spacing-text-small;
}

h1, .h1, h2, .h2 {
	font-family: $headings-font-family;
}

h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	font-family: $font-family-base;
}

h1, .h1 {

}

h2, .h2 {

}

h3, .h3 {

}

h4, .h4 {

	font-weight: 500;
}

h5, .h5 {

}

h6, .h6 {

}

.fs-1 {

}

.fs-2 {

}

.fs-3 {

}

.fs-4 {
	font-weight: 500;
}

.fs-5 {

}

.fs-6 {

}


// Form
.form-control {
	border-top-color: transparent;
	border-left-color: transparent;
	border-right-color: transparent;
}

// From hummingbird - Need to check or replace
ul {
	padding-left: 0;
	list-style: none;
}

// Link
.link {
	display: inline-block;
	border-bottom: 1px solid transparent;

	&:hover,
	&:focus {
		border-bottom: 1px solid var(--bs-link-color);
	}
}

// a:not(.alert-link) {
// 	text-decoration: none;
// 	transition: 0.25s ease-out;

// 	.material-icons {
// 		transition: 0.25s ease-out;
// 	}

// }

label .color, .variant-links .color, .custom-checkbox input[type="checkbox"] + span.color {
	display: inline-block;
	width: 1.5rem;
	height: 1.5rem;
	margin: 0.3125rem;
	margin-right: 0.5rem;
	margin-left: 0;
	cursor: pointer;
	background-size: contain;
	border: 1px solid $input-border-color;
	border-radius: 4px;
	transition: 0.25s ease-out;

	&.color-sm {
		width: 1rem;
		height: 1rem;
	}

	&.active, &:hover {
		border: 2px solid $primary;
	}

}

.input-color {
	display: none;
}

.bright {
	color: var(--bs-gray-100);
}

.dark {
	color: var(--bs-gray-800);
}




// Tablet
@include media-breakpoint-down(md) {



}


// Mobile
@include media-breakpoint-down(sm) {

    body{
		font-size: $font-size-sm;
    }

	// Warning !! Error in notice design pt = px !!!
	// Note : (61px) 26px 18px 16px (15px) 14px (13px) 12px 10px

	// Head
	h1, .h1, .fs-1 {
		//font-size: $h2-font-size; // 26pt/35px
		font-size: 26px;
	}

	// Heading 02
	h2, .h2, .fs-2 {
		//font-size: $font-size-base * 1.33; // 16pt/21px
		font-size: 18px;
	}

	// Subhead 01
	h3, .h3, .fs-3 {
		//font-size: $h3-font-size; // 12pt/16px
		font-size: 16px;
	}

	// Subhead 02
	h4, .h4, .fs-4 {
		//font-size: $font-size-base * 1.33; // 16pt/21px
		font-size: 16px;
		font-weight: 500;
	}

	// Paragraph 01
	h5, .h5, .fs-5 {
		//font-size: $font-size-base * 1.17; // 14pt/19px
		font-size: 14px;
	}

	h6, .h6, .fs-6 {
		font-size: 12px;
	}

	small, .small {
		font-size: 10px;
	}

}
;@import "sass-embedded-legacy-load-done:43037";